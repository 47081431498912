import React, { useEffect, useState } from 'react';
import { SvgIcon } from '../../../../Common';

const EditableFeatureLine = ({
    feature,
    handleFeatureChange,
    index,
}: {
    feature: string;
    handleFeatureChange: (index: number, value: string) => void;
    index: number;
}) => {
    const [featureValue, setFeatureValue] = useState<string>(feature);

    useEffect(() => {
        setFeatureValue(feature);
    }, [feature]);

    return (
        <div className="flex items-center w-full gap-x-2 h-6">
            <SvgIcon
                icon="CHECK_ICON_BILL"
                className="stroke-[#449745] pt-1.5"
            />
            <input
                onChange={(e) => {
                    setFeatureValue(e.target.value);
                }}
                onBlur={() => handleFeatureChange(index, featureValue)}
                value={featureValue}
                className="w-full text-[#449745] text-xs bg-transparent !border-transparent"
                type="text"
            />
        </div>
    );
};

export default EditableFeatureLine;
