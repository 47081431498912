import React from 'react';
import {
    formatNumber,
    formatValue,
    getAuthStatus,
    getCurrencySign,
    getDateFormat,
    getSettleStatus,
} from 'data/utils/common';
import { getTransactionStatus } from 'data/utils/transactions';
import {
    CurrenciesConstant,
    ModalConstants,
    TransactionStatusKeys,
} from 'data/constants/common.constants';
import { PaymentProviderConstant } from 'data/constants/payment.constants';
import { getPSPNameByValue } from 'components/PaymentMethods/Configuration/configurationUtills';
import { getOperatorSymbol } from '../../RuleEngine/RuleEngine.type';
import { StatusBadge } from '../../Common';

// a² === \u00B2 for the non-ASCII characters, consider encoding them.
// a¹ === \u00B9 for the non-ASCII characters, consider encoding them.

interface IConditionType {
    label: string;
    operator: string;
    value: string;
}
interface IRule {
    conditions: IConditionType[];
}

const getUserCurrency = (key: string, transaction: any) => {
    if (key === 'PAYMODUM') {
        return transaction?.transactionBaseCurrencyConverted as string;
    }
    if (transaction?.currencyiso3a) {
        return transaction?.currencyiso3a as string;
    }
    return transaction?.currency as string;
};

const getCurrencyLabel = (amount: string, userCurrency: string) => {
    const currency = formatNumber(Number(amount));
    return currency
        ? `${
              getCurrencySign(userCurrency as CurrenciesConstant) as string
          } ${currency}`
        : null;
};

const formatTransactionBaseAmount = (
    amount: number | undefined,
    currency: string | undefined
): string => {
    if (!amount || !currency) return '-';
    return `${formatNumber(amount)} ${currency}`;
};

const getValue = (value: string | number | undefined | null) => {
    return value ?? '-';
};

const getTransactionMethod = (method: string) => {
    switch (method) {
        case 'TRUST_PAYIN':
            return 'Deposit';
        case 'TRUST_PAYOUT':
            return 'Withdrawal';
        case 'CLEO_PAYOUT':
            return 'Withdrawal';
        case 'CLEO_PAYIN':
            return 'Deposit';
        default:
            return 'Withdrawal';
    }
};

const getIsForeignerLabel = (isForeigner: boolean | undefined): string => {
    if (isForeigner === undefined) return '-';
    return isForeigner ? 'true' : 'false';
};

const formatAddress = (
    line1: string | undefined,
    line2: string | undefined
): string => {
    if (!line1) return '-';
    return `${line1} ${line2 ?? ''}`.trim();
};

const getPaymentFields = (key: string, transaction: any) => {
    const userCurrency = getUserCurrency(key, transaction);
    const baseAmount = formatNumber(Number(transaction?.baseamount));
    const amountWithCurrency = getCurrencyLabel(baseAmount, userCurrency);

    switch (key) {
        case PaymentProviderConstant.CLEO:
            return [
                {
                    'method\u00B9': getValue(transaction?.method),
                    'MerchantID\u00B9': getValue(
                        transaction?.transactionDetails?.merchantnumber
                    ),
                    'transactionID\u00B2': getValue(transaction?._id),
                    'created\u00B9': getValue(
                        getDateFormat(transaction?.createdAt as string)
                    ),
                    'brandID\u00B9': getValue(transaction?.brandId),
                    'type\u00B9': getTransactionMethod(transaction?.method),
                    'settle status\u00B9': (
                        <StatusBadge status={getSettleStatus(transaction)} />
                    ),
                    'auth status\u00B9': (
                        <StatusBadge status={getAuthStatus(transaction)} />
                    ),
                    'initial_PSP\u00B9': getPSPNameByValue(
                        transaction?.initialPsp
                    ),
                    'final_PSP\u00B9': getPSPNameByValue(transaction?.finalPsp),
                    'transaction_amount\u00B9': `${formatNumber(
                        Number(transaction?.baseamount),
                        2
                    )} ${transaction?.currency as string}`,
                    'transaction_Base_Amount\u00B9':
                        formatTransactionBaseAmount(
                            transaction.transactionBaseAmountConverted,
                            transaction.transactionBaseCurrencyConverted
                        ),
                    'update_Date\u00B9': getValue(
                        getDateFormat(transaction?.updatedAt as string)
                    ),
                    'currency\u00B9': transaction?.currency || 'CLP',
                    'user_email\u00B9': getValue(transaction?.email),
                    'user_country\u00B9': getValue(transaction?.country),
                },
            ];
        case PaymentProviderConstant.TRUST:
            return [
                {
                    'method\u00B9': getValue(transaction?.method),
                    'MerchantID\u00B9': getValue(
                        transaction?.transactionDetails?.merchantnumber
                    ),
                    'transactionID\u00B9': getValue(transaction?._id),
                    'created\u00B9': getValue(
                        getDateFormat(transaction?.createdAt)
                    ),
                    'brandID\u00B9': getValue(transaction?.brandId),
                    'type\u00B9': getTransactionMethod(transaction.method),
                    'settle status\u00B9': (
                        <StatusBadge status={getSettleStatus(transaction)} />
                    ),
                    'auth status\u00B9': (
                        <StatusBadge status={getAuthStatus(transaction)} />
                    ),
                    'initial_PSP\u00B9': getPSPNameByValue(
                        transaction?.initialPsp
                    ),
                    'final_PSP\u00B9': getPSPNameByValue(transaction?.finalPsp),
                    'transaction_amount\u00B9': `${formatNumber(
                        Number(transaction?.baseamount),
                        2
                    )} ${transaction?.currency as string}`,
                    'transaction_Base_Amount\u00B9':
                        formatTransactionBaseAmount(
                            transaction.transactionBaseAmountConverted,
                            transaction.transactionBaseCurrencyConverted
                        ),
                    'update_Date\u00B9': getValue(
                        getDateFormat(transaction?.updatedAt)
                    ),
                    'currency\u00B9': getValue(transaction?.currency),
                    'user_email\u00B9': getValue(transaction?.email),
                    'user_country\u00B9': getValue(transaction?.country),
                },
            ];
        case PaymentProviderConstant.PAYMODUM:
            return [
                {
                    'method\u00B9': transaction?.method || 'TRUST_PAYIN',
                    'MerchantID\u00B9': getValue(transaction?.merchantnumber),
                    'transactionID\u00B9': getValue(transaction?._id),
                    'created\u00B9': getValue(
                        getDateFormat(transaction?.createdAt)
                    ),
                    'brandID\u00B9': getValue(transaction?.brandId),
                    'type\u00B9': getValue(transaction?.paymenttypedescription),
                    'settle status\u00B9': (
                        <StatusBadge status={getSettleStatus(transaction)} />
                    ),
                    'auth status\u00B9': (
                        <StatusBadge status={getAuthStatus(transaction)} />
                    ),
                    'initial_PSP\u00B9': getPSPNameByValue(
                        transaction?.initialPsp
                    ),
                    'final_PSP\u00B9': getPSPNameByValue(transaction?.finalPsp),
                    'transaction_amount\u00B9': amountWithCurrency,
                    'transaction_Base_Amount\u00B9':
                        formatTransactionBaseAmount(
                            transaction.transactionBaseAmountConverted,
                            transaction.transactionBaseCurrencyConverted
                        ),
                    'update_Date\u00B9': getValue(
                        getDateFormat(
                            transaction?.updatedAt || transaction?.createdAt
                        )
                    ),
                    'currency\u00B9': getValue(transaction?.userInfo?.currency),
                    'user_email\u00B9': getValue(transaction?.userInfo?.email),
                    'user_country\u00B9': getValue(
                        transaction?.userInfo?.country ||
                            transaction?.userInfo?.billing_address?.country
                    ),
                },
            ];
        case PaymentProviderConstant.BRITE:
            return [
                {
                    'method\u00B9': transaction?.method || 'TRUST_PAYIN',
                    'MerchantID\u00B9': getValue(transaction?.merchantnumber),
                    'transactionID\u00B9': getValue(transaction?._id),
                    'created\u00B9': getValue(
                        getDateFormat(
                            transaction?.createdAt || transaction?.updatedAt
                        )
                    ),
                    'brandID\u00B9': getValue(transaction?.brandId),
                    'type\u00B9': getTransactionMethod(transaction.method),
                    'settle status\u00B9': (
                        <StatusBadge status={getSettleStatus(transaction)} />
                    ),
                    'auth status\u00B9': (
                        <StatusBadge status={getAuthStatus(transaction)} />
                    ),
                    'initial_PSP\u00B9': getPSPNameByValue(
                        transaction?.initialPsp
                    ),
                    'final_PSP\u00B9': getPSPNameByValue(transaction?.finalPsp),
                    'transaction_amount\u00B9': amountWithCurrency,
                    'transaction_Base_Amount\u00B9':
                        formatTransactionBaseAmount(
                            transaction.transactionBaseAmountConverted,
                            transaction.transactionBaseCurrencyConverted
                        ),
                    'update_Date\u00B9': getValue(
                        getDateFormat(
                            transaction?.updatedAt || transaction?.createdAt
                        )
                    ),
                    'currency\u00B9': getValue(transaction?.currency),
                    'user_email\u00B9': getValue(transaction?.email),
                    'user_country\u00B9': getValue(transaction?.country),
                },
            ];
        default:
            return [];
    }
};

const getUserInformationFields = (key: string, transaction: any) => {
    const userCurrency = getUserCurrency(key, transaction);

    switch (key) {
        case PaymentProviderConstant.CLEO:
            return [
                {
                    'playerID\u00B9': getValue(transaction?.userId),
                    'firstname\u00B9': getValue(transaction?.firstName),
                    'lastName\u00B9': getValue(transaction?.lastName),
                    'tag\u00B9': getValue(
                        transaction?.tags
                            ?.map((item: string) => item)
                            ?.join(', ')
                    ),
                    'email_address\u00B9': getValue(transaction?.email),
                    'phone_number\u00B9': getValue(transaction?.phone),
                    'country_code\u00B9': getValue(transaction?.countryCode),
                    'marital_status\u00B2': getValue(
                        transaction?.transactionDetails?.maritalStatus
                    ),
                    'is_foreigner\u00B2': getIsForeignerLabel(
                        transaction?.transactionDetails?.isForeigner
                    ),
                    'Address\u00B9': formatAddress(
                        transaction?.address?.line1,
                        transaction?.address?.line2
                    ),
                    'city\u00B9': getValue(transaction?.address?.city),
                    'state\u00B9': getValue(transaction?.address?.state),
                    'country\u00B9': getValue(transaction?.address?.country),
                    'zip_code\u00B9': getValue(
                        transaction?.address?.postalCode
                    ),
                    'register_date\u00B9': getValue(
                        getDateFormat(transaction?.createdAt as string)
                    ),
                    'gender\u00B9': getValue(transaction?.gender),
                    'email_verification\u00B9': getValue(
                        transaction?.emailVerification
                    ),
                    'verification_date\u00B9': getDateFormat(
                        transaction?.verificationDate
                    ),
                    'partner_name\u00B9': getValue(transaction?.partnerName),
                    'user_balance\u00B9': getValue(
                        getCurrencyLabel(transaction?.userBalance, userCurrency)
                    ),
                },
            ];
        case PaymentProviderConstant.TRUST:
            return [
                {
                    'playerID\u00B9': getValue(transaction?.userId),
                    'firstname\u00B9': getValue(transaction?.firstName),
                    'lastName\u00B9': transaction?.lastName ?? '-',
                    'tag\u00B9': getValue(
                        transaction?.tags
                            ?.map((item: string) => item)
                            ?.join(', ')
                    ),
                    'email_address\u00B9': getValue(transaction?.email),
                    'phone_number\u00B9': getValue(transaction?.phone),
                    'country_code\u00B9': getValue(transaction?.countryCode),
                    'Address\u00B9': formatAddress(
                        transaction?.address?.line1,
                        transaction?.address?.line2
                    ),
                    'city\u00B9': getValue(transaction?.address?.city),
                    'state\u00B9': getValue(transaction?.address?.state),
                    'country\u00B9': getValue(transaction?.country),
                    'zip_code\u00B9': getValue(
                        transaction?.card?.billingAddress?.postcode ??
                            transaction?.card?.billingAddress?.postalCode
                    ),
                    'register_date\u00B9': getDateFormat(
                        transaction?.createdAt as string
                    ),
                    'gender\u00B9': getValue(transaction?.gender),
                    'email_verification\u00B9': getValue(
                        transaction?.emailVerification
                    ),
                    'verification_date\u00B9': getDateFormat(
                        transaction?.verificationDate
                    ),
                    'partner_name\u00B9': getValue(transaction?.partnerName),
                    'user_balance\u00B9': getValue(
                        getCurrencyLabel(transaction?.userBalance, userCurrency)
                    ),
                },
            ];
        case PaymentProviderConstant.PAYMODUM:
            return [
                {
                    'playerID\u00B9': getValue(transaction?._id),
                    'firstname\u00B9': getValue(transaction?.firstName),
                    'lastName\u00B9': getValue(transaction?.lastName),
                    'tag\u00B9': getValue(
                        transaction?.tags
                            ?.map((item: string) => item)
                            ?.join(', ')
                    ),
                    'email_address\u00B9': getValue(transaction?.email),
                    'phone_number\u00B9': getValue(transaction?.phone),
                    // country_code: userInfo?.countryCode ?? '-',
                    'Address\u00B9': formatAddress(
                        transaction?.address?.line1,
                        transaction?.address?.line2
                    ),
                    'city\u00B9': getValue(transaction?.address?.city),
                    'state\u00B9': getValue(transaction?.address?.state),
                    'country\u00B9': getValue(transaction?.country),
                    'zip_code\u00B9': getValue(
                        transaction?.card?.billingAddress?.postcode ??
                            transaction?.card?.billingAddress?.postalCode
                    ),
                    'register_date\u00B9':
                        transaction?.transactionstartedtimestamp
                            ? getDateFormat(
                                  transaction?.transactionstartedtimestamp as string
                              )
                            : '-',
                    'gender\u00B9': getValue(transaction?.gender),
                    'email_verification\u00B9': getValue(
                        transaction?.emailVerification
                    ),
                    'verification_date\u00B9': getDateFormat(
                        transaction?.verificationDate
                    ),
                    'partner_name\u00B9': getValue(transaction?.partnerName),
                    'user_balance\u00B9': getValue(
                        getCurrencyLabel(transaction?.userBalance, userCurrency)
                    ),
                },
            ];
        case PaymentProviderConstant.BRITE:
            return [
                {
                    'playerID\u00B9': getValue(transaction?._id),
                    'firstname\u00B9': getValue(transaction?.firstName),
                    'lastName\u00B9': getValue(transaction?.lastName),
                    'tag\u00B9': getValue(
                        transaction?.tags
                            ?.map((item: string) => item)
                            ?.join(', ')
                    ),
                    'email_address\u00B9': getValue(transaction?.email),
                    'phone_number\u00B9': getValue(transaction?.phone),
                    'country_code\u00B9': getValue(transaction?.countryCode),
                    'marital_status\u00B2': getValue(
                        transaction?.maritalStatus
                    ),
                    'is_foreigner\u00B2': getIsForeignerLabel(
                        transaction?.isForeigner
                    ),
                    'Address\u00B9': formatAddress(
                        transaction?.address?.line1,
                        transaction?.address?.line2
                    ),
                    'city\u00B9': getValue(transaction?.address?.city),
                    'state\u00B9': getValue(transaction?.address?.state),
                    'country\u00B9': getValue(transaction?.country),
                    'zip_code\u00B9': getValue(
                        transaction?.address?.postalCode ??
                            transaction?.card?.billingAddress?.postcode ??
                            transaction?.card?.billingAddress?.postalCode
                    ),
                    'register_date\u00B9': getValue(
                        getDateFormat(
                            (transaction?.createdAt ||
                                transaction?.updateAt ||
                                transaction?.created) as string
                        )
                    ),
                    'gender\u00B9': getValue(transaction?.gender),
                    'email_verification\u00B9': getValue(
                        transaction?.emailVerification
                    ),
                    'verification_date\u00B9': getDateFormat(
                        transaction?.verificationDate
                    ),
                    'partner_name\u00B9': getValue(transaction?.partnerName),
                    'user_balance\u00B9': getValue(
                        getCurrencyLabel(transaction?.userBalance, userCurrency)
                    ),
                },
            ];
        default:
            return [];
    }
};

const getTransactionInformationFields = (key: string, transaction: any) => {
    switch (key) {
        case PaymentProviderConstant.CLEO:
            return [
                {
                    'psp_ref\u00B2': '-',
                    'addition_info\u00B9': '-',
                    'origin_tx\u00B2': getValue(
                        transaction?.transactionDetails?.sessionId
                    ),
                    'tr_ref_id\u00B2': '-',
                    'tx.type\u00B9': getValue(transaction?.transactionType),
                    'initial_psp_amount\u00B9': `${formatNumber(
                        Number(transaction?.baseamount)
                    )} CLP`,
                    'psp_status\u00B2': (
                        <StatusBadge status={transaction?.status} />
                    ),
                    'account Number\u00B2': getValue(
                        transaction?.transactionDetails?.accountNumber
                    ),
                    'account Type\u00B2': getValue(
                        transaction?.transactionDetails?.accountType
                    ),
                    'bank\u00B2': getValue(
                        transaction?.transactionDetails?.bank
                    ),
                    'psp_user_ref_id\u00B2': getValue(
                        transaction?.transactionDetails?.externalKey
                    ),
                    'psp_fraud_score\u00B2': '-',
                    'blocked\u00B9': getValue(transaction?.blocked?.toString()),
                    'updated_by\u00B9': getValue(
                        transaction?.updatedByUsername
                    ),
                    'ip_city\u00B9': getValue(transaction?.ipCity),
                    'ip_region\u00B9': getValue(transaction?.ipRegion),
                    'card_issuer_country\u00B2': getValue(transaction?.country),
                    'ip_country\u00B9': getValue(transaction?.ipCountry),
                },
            ];
        case PaymentProviderConstant.TRUST:
            return [
                {
                    'psp_ref\u00B2': getValue(
                        transaction?.transactionDetails?.transactionreference
                    ),
                    'addition_info\u00B9': '-',
                    'origin_tx\u00B2': getValue(
                        transaction?.transactionDetails?.tid
                    ),
                    'tax_ref_id\u00B2': getValue(
                        transaction?.transactionDetails?.transactionreference
                    ),
                    'state\u00B9': getValue(transaction?.address?.state),
                    'authCode\u00B2': getValue(
                        transaction?.transactionDetails?.authcode
                    ),
                    'initial_psp_amount\u00B9': `${formatNumber(
                        Number(transaction?.baseamount)
                    )} ${
                        (transaction?.transactionBaseCurrencyConverted as string) ||
                        ''
                    }`,
                    'psp_status\u00B2': (
                        <StatusBadge
                            status={getTransactionStatus(transaction)}
                        />
                    ),
                    'psp_account\u00B2': getValue(
                        transaction?.transactionDetails?.accounttypedescription
                    ),
                    'psp_user_ref_id\u00B2': getValue(
                        transaction?.transactionDetails?.rut
                    ),
                    'psp_fraud_score\u00B2': '-',
                    'blocked\u00B9': getValue(transaction?.blocked?.toString()),
                    'updated_by\u00B9': getValue(
                        transaction?.updatedByUsername
                    ),
                    'ip_city\u00B9': getValue(
                        transaction?.transactionDetails?.ipCity
                    ),
                    'ip_region\u00B9': getValue(
                        transaction?.transactionDetails?.ipRegion
                    ),
                    'card_issuer_country\u00B2': getValue(transaction?.country),
                    'ip_country\u00B9': getValue(
                        transaction?.transactionDetails?.ipCountry
                    ),
                },
            ];
        case PaymentProviderConstant.PAYMODUM:
            return [
                {
                    'psp_ref\u00B2': getValue(
                        transaction?.transactionreference
                    ),
                    'addition_info\u00B9': '-',
                    'origin_tx\u00B2': getValue(transaction?._id),
                    'tax_ref_id\u00B2': '-',
                    'state\u00B9': getValue(transaction?.status),
                    'authCode\u00B2': getValue(transaction?.authcode),
                    'initial_psp_amount\u00B9': `${formatNumber(
                        Number(transaction?.baseamount)
                    )} ${
                        (transaction?.transactionBaseCurrencyConverted as string) ||
                        ''
                    }`,
                    'psp_status\u00B2': (
                        <StatusBadge
                            status={getTransactionStatus(transaction)}
                        />
                    ),
                    'psp_account\u00B2': getValue(
                        transaction?.accounttypedescription
                    ),
                    'psp_user_ref_id\u00B2': getValue(transaction?.rut),
                    'psp_fraud_score\u00B2': '-',
                    'blocked\u00B9': getValue(transaction?.blocked?.toString()),
                    'updated_by\u00B9': getValue(
                        transaction?.updatedByUsername
                    ),
                    'ip_city\u00B9': getValue(transaction?.ipCity),
                    'ip_region\u00B9': getValue(transaction?.ipRegion),
                    'card_issuer_country\u00B2': '-',
                    'ip_country\u00B9': getValue(transaction?.ipCountry),
                },
            ];
        case PaymentProviderConstant.BRITE:
            return [
                {
                    'psp_ref\u00B2': '-',
                    'addition_info\u00B9': '-',
                    'origin_tx\u00B2': getValue(transaction?.externalKey),
                    'tr_ref_id\u00B2': '-',
                    'tx.type\u00B9': getValue(
                        transaction?.transactionDetails?.transactionType
                    ),
                    'initial_psp_amount\u00B9': `${formatNumber(
                        Number(transaction?.baseamount)
                    )} CLP`,
                    'psp_status\u00B2': '-',
                    'account Number\u00B2': getValue(
                        transaction?.transactionDetails?.accountNumber
                    ),
                    'account Type\u00B2': getValue(
                        transaction?.transactionDetails?.accountType
                    ),
                    'bank\u00B2': getValue(
                        transaction?.transactionDetails?.bank?.name
                    ),
                    'psp_user_ref_id\u00B2': getValue(transaction?.externalKey),
                    'psp_fraud_score\u00B2': '-',
                    'blocked\u00B9': getValue(transaction?.blocked?.toString()),
                    'updated_by\u00B9': getValue(
                        transaction?.updatedByUsername
                    ),
                    'ip_city\u00B9': getValue(transaction?.ipCity),
                    'ip_region\u00B9': getValue(transaction?.ipRegion),
                    'card_issuer_country\u00B2': '-',
                    'ip_country\u00B9': getValue(transaction?.ipCountry),
                },
            ];
        default:
            return [];
    }
};

const getKycStatusFields = (transaction: any) => {
    return [
        {
            'abuse\u00B2': '-',
            'kyc status\u00B9': (
                <StatusBadge
                    status={
                        transaction?.kycVerified
                            ? TransactionStatusKeys.VERIFIED
                            : TransactionStatusKeys.NOT_VERIFIED
                    }
                />
            ),
        },
    ];
};

const refactorRule = (transaction: { verifiedRules: IRule[] }) => {
    const dataSet = new Set();
    transaction?.verifiedRules?.forEach((item) => {
        item?.conditions?.forEach((condition) =>
            dataSet.add(
                `${condition?.label} ${getOperatorSymbol(
                    condition?.operator
                )} ${formatValue(condition?.value) as string}`
            )
        );
    });
    return Array.from(dataSet);
};

const getOtherFields = (transaction: any) => {
    return [
        {
            'rules\u00B9': refactorRule(transaction)?.join(', ') || '-',
            'bonus_code\u00B9': transaction?.bonusCode || '-',
        },
    ];
};

const getFinancialFields = (key: string, transaction: any) => {
    const baseFee: string =
        transaction?.transactionBaseAmountConverted !== undefined &&
        transaction.amountFee !== undefined
            ? (
                  (formatNumber(
                      transaction.transactionBaseAmountConverted
                  ) as unknown as number) -
                  (formatNumber(
                      Number(transaction.amountFee)
                  ) as unknown as number)
              ).toFixed(2)
            : '0.00';
    switch (key) {
        case PaymentProviderConstant.CLEO:
            return [
                {
                    'fee\u00B2': transaction?.transactionDetails?.amountFee
                        ? `${formatNumber(
                              Number(transaction?.transactionDetails?.amountFee)
                          )} ${transaction?.currency as string}`
                        : '-',
                    'amount_base\u00B9': `${formatNumber(
                        transaction?.transactionBaseAmountConverted
                    )} ${
                        transaction?.transactionBaseCurrencyConverted as string
                    }`,
                    'account_type\u00B9': getValue(
                        transaction?.transactionDetails?.accountType
                    ),
                    'account_number\u00B9': getValue(
                        transaction?.transactionDetails?.accountNumber
                    ),
                    'bank\u00B9': getValue(
                        transaction?.transactionDetails?.bank
                    ),
                    'pSP_fee\u00B2': transaction?.transactionDetails?.amountFee
                        ? `${formatNumber(
                              Number(transaction?.transactionDetails?.amountFee)
                          )} ${transaction?.currency as string}`
                        : '-',
                    'pSP_base_fee\u00B9': `${formatNumber(Number(baseFee))} ${
                        transaction?.transactionBaseCurrencyConverted as string
                    }`,
                    'auth_amount\u00B9': getValue(transaction?.baseamount),
                    'auth_base_amount\u00B9': getValue(transaction?.baseamount),
                },
            ];
        case PaymentProviderConstant.TRUST:
            return [
                {
                    'fee\u00B2': transaction?.transactionDetails?.amountFee
                        ? `${formatNumber(
                              Number(transaction?.transactionDetails?.amountFee)
                          )} ${transaction?.currency as string}`
                        : '-',
                    'amount_base\u00B9': `${formatNumber(
                        transaction?.transactionBaseAmountConverted
                    )} ${
                        transaction?.transactionBaseCurrencyConverted as string
                    }`,
                    'account_type\u00B2': getValue(
                        transaction?.transactionDetails?.accounttypedescription
                    ),
                    'account_number\u00B9': '-',
                    'Card\u00B2': getValue(transaction?.card?.creditCardType),
                    'Card_Holder_Name\u00B2': getValue(
                        transaction?.card?.name_on_card
                    ),
                    'bank\u00B2': getValue(transaction?.bank),
                    'pSP_fee\u00B9': `-`,
                    'pSP_base_fee\u00B9': `-`,
                    'auth_amount\u00B9': getValue(transaction?.baseamount),
                    'auth_base_amount\u00B9': getValue(transaction?.baseamount),
                },
            ];
        case PaymentProviderConstant.PAYMODUM:
            return [
                {
                    'fee\u00B9': '-',
                    'amount_base\u00B9': `${formatNumber(
                        Number(transaction?.baseamount)
                    )} ${
                        transaction?.transactionBaseCurrencyConverted as string
                    }`,
                    'account_type\u00B2': getValue(
                        transaction?.accounttypedescription
                    ),
                    'account_number\u00B9': getValue(
                        transaction?.accountNumber
                    ),
                    'Card\u00B2': getValue(transaction?.maskedpan),
                    'Card_Holder_Name\u00B2': getValue(transaction?.issuer),
                    'bank\u00B2': getValue(transaction?.bank),
                    'pSP_fee\u00B9': `-`,
                    'pSP_base_fee\u00B9': `-`,
                    'auth_amount\u00B2': '-',
                    'auth_base_amount\u00B2': `${formatNumber(
                        Number(transaction?.baseamount)
                    )} ${
                        transaction?.transactionBaseCurrencyConverted as string
                    }`,
                },
            ];
        case PaymentProviderConstant.BRITE:
            return [
                {
                    'fee\u00B2': `${formatNumber(
                        Number(transaction?.amountFee)
                    )} ${transaction?.currency as string}`,
                    'amount_base\u00B9': `${formatNumber(
                        transaction?.transactionBaseAmountConverted
                    )} ${
                        transaction?.transactionBaseCurrencyConverted as string
                    }`,
                    'account_type\u00B9': getValue(
                        transaction?.transactionDetails?.accountType
                    ),
                    'account_number\u00B9': getValue(
                        transaction?.transactionDetails?.accountNumber
                    ),
                    'bank\u00B9': getValue(transaction?.bank?.name),
                    'pSP_fee\u00B2': transaction?.transactionDetails?.amountFee
                        ? `${formatNumber(
                              Number(transaction?.transactionDetails?.amountFee)
                          )} ${transaction?.currency as string}`
                        : '-',
                    'pSP_base_fee\u00B9': `${formatNumber(Number(baseFee))} ${
                        transaction?.transactionBaseCurrencyConverted as string
                    }`,
                    'auth_amount\u00B9': `${formatNumber(
                        Number(transaction?.transactionDetails?.amountFee)
                    )} ${transaction?.currency as string}`,
                    'auth_base_amount\u00B9': `${formatNumber(
                        transaction?.transactionBaseAmountConverted
                    )} ${
                        transaction?.transactionBaseCurrencyConverted as string
                    }`,
                },
            ];
        default:
            return [];
    }
};

const getTransactionTabData = (key: string, transaction: any) => {
    switch (key) {
        case ModalConstants.PAYMENT:
            return getPaymentFields(transaction?.pspType, transaction);
        case ModalConstants.USER_INFORMATION:
            return getUserInformationFields(transaction?.pspType, transaction);
        case ModalConstants.TRANSACTION_INFORMATION:
            return getTransactionInformationFields(
                transaction?.pspType,
                transaction
            );
        case ModalConstants.KYC_STATUS:
            return getKycStatusFields(transaction);
        case ModalConstants.OTHER:
            return getOtherFields(transaction);
        case ModalConstants.FINANCIAL:
            return getFinancialFields(transaction?.pspType, transaction);
        default:
            return [];
    }
};

export { getTransactionTabData, getPaymentFields };
