import { Dispatch, SetStateAction } from 'react';
import { ITransactions } from 'data/types/response';
import {
    BulkActionTypes,
    TransactionStatusKeys,
    TransactionStatusLabels,
} from 'data/constants/common.constants';

export const PaymentStatus = [
    {
        name: TransactionStatusLabels.ALL_PAYMENTS,
        value: TransactionStatusKeys.ALL_PAYMENTS,
    },
    {
        name: TransactionStatusLabels.INITIATED,
        value: TransactionStatusKeys.INITIATED,
    },
    { name: TransactionStatusLabels.PAID, value: TransactionStatusKeys.PAID },
    {
        name: TransactionStatusLabels.SUSPENDED,
        value: TransactionStatusKeys.SUSPENDED,
    },
    {
        name: TransactionStatusLabels.PENDING,
        value: TransactionStatusKeys.PENDING,
    },
    {
        name: TransactionStatusLabels.SUCCESS,
        value: TransactionStatusKeys.SUCCESS,
    },
    {
        name: TransactionStatusLabels.EXPIRED,
        value: TransactionStatusKeys.EXPIRED,
    },
    {
        name: TransactionStatusLabels.FAILED,
        value: TransactionStatusKeys.FAILED,
    },
    { name: TransactionStatusLabels.ERROR, value: TransactionStatusKeys.ERROR },
    {
        name: TransactionStatusLabels.CAPTURED,
        value: TransactionStatusKeys.CAPTURED,
    },
    { name: TransactionStatusLabels.HOLD, value: TransactionStatusKeys.HOLD },
    {
        name: TransactionStatusLabels.REJECT,
        value: TransactionStatusKeys.REJECT,
    },
];

export const Options = [
    { id: '', name: BulkActionTypes.BULK_ACTION },
    { id: 'v3', name: BulkActionTypes.DISABLE },
];

export interface IFilteredDataType {
    status: string[];
    search: string;
    page: number;
    limit: number;
    sortKey?: string;
}
export interface ITransactionHeader {
    setFilterData: Dispatch<SetStateAction<IFilteredDataType>>;
    filterData: IFilteredDataType;
    setCheckedTransaction?: Dispatch<SetStateAction<Array<string>>>;
    checkedTransaction?: Array<string>;
    setIsTableFilter: Dispatch<SetStateAction<boolean>>;
    setIsActionModalOpen: Dispatch<SetStateAction<boolean>>;
    selectedRowStatus: string;
}

export interface ITransactionTable {
    loading: boolean;
    currentPage: number;
    transactionData: Array<ITransactions>;
    setFilterData: Dispatch<SetStateAction<IFilteredDataType>>;
    filterData: IFilteredDataType;
    setCurrentPage: Dispatch<SetStateAction<number>>;
    tableFilterColumn: Array<IHeaderList>;
    checkedTransactions: Array<string>;
    setCheckedTransactions: Dispatch<SetStateAction<Array<string>>>;
    totalPage: number;
    setSelectedRowStatus: Dispatch<SetStateAction<string>>;
}

export interface IHeaderList {
    value: string;
    key: string;
    isChecked: boolean | undefined;
}

export interface ITransactionTableFilterModal {
    className: string;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    tableFilterColumn: Array<IHeaderList>;
}

export interface IFetchTransactionPayload {
    status: string[];
    search: string;
    brandIds?: string[] | string;
}

export interface Rule {
    name: string;
}

export const TransactionStatusOptions = [
    { _id: '0', name: TransactionStatusLabels.PENDING },
    { _id: '1', name: TransactionStatusLabels.MANUAL },
    { _id: '2', name: TransactionStatusLabels.SUSPENDED },
    { _id: '3', name: TransactionStatusLabels.CANCELLED },
];

export interface ITransactionActionModal {
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    checkedTransactions: string[];
    setCheckedTransactions: Dispatch<SetStateAction<string[]>>;
    selectedRowStatus: string;
    fetchTransactionData: () => void;
}

export interface ITransactionColumn {
    checkedTransactions: string[];
    setCheckedTransactions: (value: string[]) => void;
    setSelectedRowStatus?: Dispatch<SetStateAction<string>>;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export interface ITransaction {
    _id: string;
    pspType: string;
    transactionDetails?: {
        settlestatus?: string;
        errorcode?: string;
    };
}
