import { useEffect, useState } from 'react';
import LoadingSpinner from 'components/Common/Loader/Loader';
import { IBrandData, IBrandUserData, IAdminUserData } from 'data/common';
import { getAllBrands, getUserByCompany } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import usePermission from 'hooks/commonHook';
import { useAuthContext } from 'context/Auth/AuthContext';
import { AppActionsEnum } from 'context/Auth/AuthContextValues';
import { PageIdentifiers } from 'data/constants/common.constants';
import { BrandsHeader } from './BrandsHeader';
import { BrandsTable } from './BrandTable';
import { EditBrandsDrawer } from './BrandsDrawer/EditBrandDrawer';
import { AddBrandsDrawer } from './BrandsDrawer/AddBrandDrawer';
import { IBrands, IGetAllBrandsData } from './Brads.type';
import { useCompanyOptionList, useFetchCompanies } from '../useAdminCompany';

const Brands = () => {
    const { auth, dispatch } = useAuthContext();
    const [brands, setBrands] = useState<IBrandData[]>([]);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [brandLoading, setBrandLoading] = useState<boolean>(false);
    const [brand, setBrand] = useState<IBrandUserData | null>(null);
    const [checkedBrands, setCheckedBrands] = useState<string[]>([]);
    const [adminUsers, setAdminUsers] = useState<IAdminUserData[]>([
        { _id: '', name: 'Select', adminUserId: '' },
    ]);
    const [isEditBrandsDrawer, setIsEditBrandsDrawer] =
        useState<boolean>(false);
    const { allCompanies } = useFetchCompanies({ isOpen, auth });
    const companyOptionList = useCompanyOptionList({ auth, allCompanies });

    const isWrite = usePermission({
        checkPermission: PageIdentifiers.SUB_BRANDS,
    });

    const isAllowedUser = auth?.isSuperAdmin ? true : isWrite;

    const getAllBrandsData = () => {
        const payload: { filter: IGetAllBrandsData } = { filter: {} };
        if (auth.selectedBrand) {
            payload.filter = { brandId: auth?.selectedBrand?._id };
        } else if (auth.selectedCompany) {
            payload.filter = { companyId: auth?.selectedCompany?._id };
        }
        setIsLoading(true);
        getAllBrands(payload)
            .then((res) => {
                dispatch({
                    type: AppActionsEnum.SET_BRANDS_LIST,
                    payload: {
                        brandsList: res?.brands,
                    },
                });
                setBrands(res.brands || []);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    useEffect(() => {
        getAllBrandsData();
    }, [
        auth.role,
        auth.isSuperAdmin,
        auth?.selectedBrand,
        auth?.selectedCompany,
    ]);

    const getUsers = (companyId: string) => {
        getUserByCompany({
            companyId,
        })
            .then((res) => {
                const adminUserData = res?.users?.map(
                    (value: IAdminUserData) => ({
                        ...value,
                        _id: value.name,
                        adminUserId: value._id,
                    })
                );
                adminUserData.unshift(
                    { _id: '', name: 'Select' },
                    {
                        adminUserId: auth?.authUser?._id,
                        name: auth?.authUser?.name,
                        _id: auth?.authUser?.name,
                    }
                );
                setAdminUsers(adminUserData);
            })
            .catch((err) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    const closeDrawer = () => {
        setAdminUsers([{ _id: '', name: 'Select', adminUserId: '' }]);
    };

    const openDrawer = () => {
        if (isOpen) {
            closeDrawer();
        } else {
            getUsers(auth?.authUser?.company?._id ?? '');
        }
        setIsEditBrandsDrawer(false);
        setBrand(null);
        setIsOpen(!isOpen);
    };

    const brandData: IBrands = {
        name: brand?.name,
        adminUser: brand?.adminUser,
        superAdminCompany: auth.isSuperAdmin
            ? brand?.companyId
            : auth?.authUser?.company?._id,
        pspType: brand?.pspType,
        locals: brand?.locals,
        currencies: brand?.currencies,
        countries: brand?.countries,
        description: brand?.description,
    };

    return (
        <div className="p-5">
            <BrandsHeader
                getAllBrandsData={getAllBrandsData}
                openDrawer={openDrawer}
                setCheckedBrands={setCheckedBrands}
                checkedBrands={checkedBrands}
                isAllowedUser={isAllowedUser}
            />
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <BrandsTable
                    brands={brands}
                    setBrands={setBrands}
                    setBrand={setBrand}
                    checkedBrands={checkedBrands}
                    setCheckedBrands={setCheckedBrands}
                    setIsOpen={setIsOpen}
                    setIsEditBrandsDrawer={setIsEditBrandsDrawer}
                    getUsers={getUsers}
                    isAllowedUser={isAllowedUser}
                />
            )}
            {isEditBrandsDrawer ? (
                <EditBrandsDrawer
                    loading={brandLoading}
                    companyOptions={companyOptionList || []}
                    isCreateBrandModal
                    editSubBrandData={brand}
                    isOpen={isOpen}
                    openDrawer={openDrawer}
                    getUsers={getUsers}
                    adminUsers={adminUsers}
                    isEditBrandsDrawer={isEditBrandsDrawer}
                    getAllBrandsData={getAllBrandsData}
                    setIsOpen={setIsOpen}
                    setBrandLoading={setBrandLoading}
                    brand={brand}
                    brandData={brandData}
                    setBrand={setBrand}
                />
            ) : (
                <AddBrandsDrawer
                    loading={brandLoading}
                    companyOptions={companyOptionList || []}
                    isCreateBrandModal
                    editSubBrandData={brand}
                    isOpen={isOpen}
                    openDrawer={openDrawer}
                    getUsers={getUsers}
                    adminUsers={adminUsers}
                    isEditBrandsDrawer={isEditBrandsDrawer}
                    getAllBrandsData={getAllBrandsData}
                    setIsOpen={setIsOpen}
                    setBrandLoading={setBrandLoading}
                    brand={brand}
                    brandData={brandData}
                    setBrand={setBrand}
                />
            )}
        </div>
    );
};
export default Brands;
