import { SvgIcon } from '../../../../Common';

const FeatureLine = ({
    feature,
    color,
}: {
    feature: string;
    color: '#449745' | '#FFFFFF';
}) => {
    return (
        <div className="flex items-center gap-x-2 min-h-6 h-fit !m-0">
            {color === '#FFFFFF' ? (
                <SvgIcon icon="CHECK_ICON_BILL" className="stroke-[#FFFFFF]" />
            ) : (
                <SvgIcon icon="CHECK_ICON_BILL" className="stroke-[#449745]" />
            )}
            <div className="text-xs pb-1.5" style={{ color }}>
                {feature}
            </div>
        </div>
    );
};

export default FeatureLine;
