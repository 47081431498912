import React, { useEffect, useState } from 'react';
import {
    DatePicker,
    Drawer,
    PrimaryButton,
    RFTextField,
    SvgIcon,
} from 'components/Common';
import { Controller, useForm } from 'react-hook-form';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import { zodResolver } from '@hookform/resolvers/zod';
import CardDetails from './components/CardDetails/CardDetails';
import {
    addPaymentCardValidation,
    IPaymentCard,
    IPaymentMethodsDrawerProps,
    staticCardData,
} from '../../Billing.types';

const PaymentMethodsDrawer = ({
    setIsOpen,
    isOpen,
}: IPaymentMethodsDrawerProps) => {
    const [activeCard, setActiveCard] = useState('');
    const [updateCardId, setUpdateCardId] = useState<string | null>(null);

    const { control, handleSubmit, reset } = useForm<IPaymentCard>({
        resolver: zodResolver(addPaymentCardValidation),
        mode: 'onChange',
    });

    useEffect(() => {
        const selectedCard = staticCardData.find((card) => card.isDefault);
        if (selectedCard) {
            setActiveCard(selectedCard._id);
        }
    }, [staticCardData]);

    const today = new Date();
    const firstDayOfCurrentMonth = new Date(
        today.getFullYear(),
        today.getMonth(),
        1
    );

    const onSubmit = handleSubmit(() => {
        setUpdateCardId(null);
        reset({
            cvv: '',
            cardNumber: '',
            cardHolderName: '',
            expiry: '',
        });
    });

    return (
        <div>
            <Drawer
                drawerSize={620}
                isOpen={isOpen}
                toggleDrawer={() => setIsOpen(false)}
                drawerDirection="right"
                className="bg-pink-100 !p-8 box-border  max-h-[100vh] relative h-screen overflow-auto"
            >
                <div>
                    <div className="flex items-center justify-between">
                        <div className="text-2xl text-[#2E672F] font-merri-bold">
                            Manage Payment Methods
                        </div>
                        <SvgIcon
                            icon="CLOSE_BUTTON"
                            className="cursor-pointer"
                            onClick={() => setIsOpen(false)}
                        />
                    </div>
                    <div className="mt-8 space-y-3">
                        {staticCardData.map((card) => (
                            <CardDetails
                                key={card?._id}
                                _id={card?._id}
                                cardNumber={card?.cardNumber}
                                name={card?.cardHolderName}
                                expiry={card?.expiry}
                                cvv={card?.cvv}
                                setActiveCard={setActiveCard}
                                activeCard={activeCard}
                                setUpdateCardId={setUpdateCardId}
                                reset={reset}
                            />
                        ))}
                    </div>
                    <div className="mt-8 space-y-6">
                        <div className="text-[#131119] text-base font-poppins-bold">
                            Add another card
                        </div>
                        <form onSubmit={onSubmit}>
                            <RFTextField
                                control={control}
                                name="cardHolderName"
                                fullWidth
                                label="Cardholders name"
                                type="string"
                                asterisk
                                noArrows
                            />
                            <RFTextField
                                control={control}
                                name="cardNumber"
                                fullWidth
                                label="Card Number"
                                type="number"
                                asterisk
                                noArrows
                            />
                            <div className="grid grid-cols-2 gap-x-6">
                                <Controller
                                    name="expiry"
                                    control={control}
                                    render={({
                                        field: { onChange, ...field },
                                        fieldState,
                                    }) => (
                                        <DatePicker
                                            label="Expiry Date"
                                            options={{
                                                allowInput: true,
                                                dateFormat: 'm/Y',
                                                minDate: firstDayOfCurrentMonth,
                                                plugins: [
                                                    monthSelectPlugin({
                                                        shorthand: true, // Display shorthand month (Jan, Feb, etc.)
                                                        dateFormat: 'm/Y', // Format as MM/YYYY
                                                        altFormat: 'F Y', // Display as "January 2024"
                                                    }),
                                                ],
                                            }}
                                            placeholder="Expiry (MM/YY)"
                                            onChange={(
                                                _selectedDates: Date[],
                                                dateStr: string
                                            ) => {
                                                onChange(dateStr);
                                            }}
                                            asterisk
                                            isError={
                                                !!fieldState.error?.message
                                            }
                                            errorMessage={
                                                fieldState.error?.message ?? ''
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                                <RFTextField
                                    control={control}
                                    name="cvv"
                                    label="CVC / CVV"
                                    type="password"
                                    noArrows
                                    asterisk
                                    maxLength={3}
                                />
                            </div>
                            <PrimaryButton
                                color="#2E672F"
                                name={updateCardId ? 'Update Card' : 'Add Card'}
                                className="w-full !h-12 !mt-6 !text-base !rounded-xl"
                                variant="filled"
                                type="submit"
                            />
                        </form>
                        <div className="text-center text-[#131119]">
                            Note: We can currently store up to five cards for
                            your convenience.
                        </div>
                    </div>
                </div>
            </Drawer>
        </div>
    );
};

export default PaymentMethodsDrawer;
