import {
    formatNumber,
    getAuthStatus,
    getDateFormats,
    getSettleStatus,
} from 'data/utils/common';
import { StatusBadge, TextField } from 'components/Common';
import SvgIcon from 'components/Common/Icon/SvgIcon';
import { IIcons } from 'data/common';
import { CardConstant } from 'data/constants/payment.constants';
import { IColumnType } from 'components/Common/common';
import { getPSPNameByValue } from 'components/PaymentMethods/Configuration/configurationUtills';
import {
    IHeaderList,
    ITransaction,
    ITransactionColumn,
    Rule,
} from '../Transaction.type';
import { validStatuses } from '../TransactionHeader';

const PaymentCardList = (cardName: string): keyof IIcons => {
    switch (cardName) {
        case CardConstant.VISA:
            return CardConstant.VISA;
        case CardConstant.MASTERCARD:
            return CardConstant.MASTERCARD;
        default:
            return CardConstant.VISA;
    }
};

const getVerifiedRulesNames = (rules?: Rule[]) => {
    if (!rules || rules.length === 0) return '-';

    const filteredRules = rules.filter((ele) => ele?.name);
    const ruleNames = filteredRules.map((ele) => ele.name);
    if (ruleNames.length === 0) return '-';

    return ruleNames.length > 4
        ? `${ruleNames.slice(0, 4).join(', ')}, ...`
        : ruleNames.join(', ');
};

export const transactionTableColumn = ({
    checkedTransactions,
    setCheckedTransactions,
    setSelectedRowStatus,
    setIsOpen,
}: ITransactionColumn): IColumnType<any>[] => {
    const handleSelectTransaction = (transaction: ITransaction) => {
        const { _id, pspType, transactionDetails } = transaction;
        const isValid =
            pspType === 'TRUST' &&
            validStatuses.includes(transactionDetails?.settlestatus ?? '');

        if (!isValid) {
            setIsOpen((prevState) => !prevState);
            return;
        }

        if (!setSelectedRowStatus) return;
        setSelectedRowStatus(transactionDetails?.settlestatus ?? '');
        if (checkedTransactions[0] === _id) {
            setCheckedTransactions([]);
            setIsOpen((prevState) => !prevState);
            return;
        }
        setCheckedTransactions([_id]);
        setIsOpen((prevState) => !prevState);
    };

    return [
        {
            key: 'checked',
            width: 10,
            title: (
                <div className="flex justify-center">
                    <div className="text-xs tracking-tight font-poppins-medium">
                        Select
                    </div>
                </div>
            ),
            render: (_, i) => (
                <div className="flex justify-center">
                    <TextField
                        checked={i?._id === checkedTransactions[0]}
                        className={
                            i.pspType !== 'TRUST' ||
                            !validStatuses.includes(
                                i?.transactionDetails?.settlestatus
                            )
                                ? 'checkmark__disabled'
                                : 'cursor-pointer'
                        }
                        onClick={(e) => {
                            e.stopPropagation();
                            handleSelectTransaction(i);
                        }}
                        isCheckbox
                        type="checkbox"
                    />
                </div>
            ),
        },
        {
            key: 'method',
            title: (
                <div className="flex justify-center">
                    <div className="text-xs tracking-tight font-poppins-medium">
                        Method
                    </div>
                </div>
            ),
            width: 100,
            render: (_, i) => {
                return (
                    <div className="flex items-center justify-between gap-5">
                        <div className="flex items-center text-center h-[24px] pt-[2px]">
                            {i.method}
                        </div>

                        {(i.pspType === 'PAYMODUM' ||
                            i.paymenttypedescription) && (
                            <SvgIcon
                                className="text-center h-[24px]"
                                icon={PaymentCardList(
                                    i.pspType === 'PAYMODUM'
                                        ? i?.userInfo?.paymentMethod
                                        : i.paymenttypedescription
                                )}
                            />
                        )}
                    </div>
                );
            },
        },
        {
            key: '_id',
            title: (
                <div className="flex justify-center">
                    <div className="text-xs tracking-tight font-poppins-medium">
                        Transaction ID
                    </div>
                </div>
            ),
            width: 100,
        },
        {
            key: 'createdAt',
            title: (
                <div className="flex justify-center">
                    <div className="text-xs tracking-tight font-poppins-medium">
                        Created
                    </div>
                </div>
            ),
            render: (_, i) => (
                <div>{i?.createdAt ? getDateFormats(i?.createdAt) : '-'}</div>
            ),
            width: 100,
        },
        {
            key: 'type',
            title: (
                <div className="flex justify-center">
                    <div className="text-xs tracking-tight font-poppins-medium">
                        Type
                    </div>
                </div>
            ),
            render: (_, i) => (
                <div>
                    {i?.method === 'CLEO_PAYOUT' ? 'Withdrawal' : 'Deposit'}
                </div>
            ),
            width: 100,
        },
        {
            key: 'settleStatus',
            title: (
                <div className="flex justify-center">
                    <div className="text-xs tracking-tight font-poppins-medium">
                        Settle Status
                    </div>
                </div>
            ),
            render: (_, i) => (
                <div className="flex items-center justify-center">
                    <StatusBadge status={getSettleStatus(i)} />
                </div>
            ),
            width: 100,
        },
        {
            key: 'authStatus',
            title: (
                <div className="flex justify-center">
                    <div className="text-xs tracking-tight font-poppins-medium">
                        Auth Status
                    </div>
                </div>
            ),
            render: (_, i) => (
                <div className="flex items-center justify-center">
                    <StatusBadge status={getAuthStatus(i)} />
                </div>
            ),
            width: 100,
        },
        {
            key: 'verifiedRules',
            title: (
                <div className="flex justify-center">
                    <div className="text-xs tracking-tight font-poppins-medium">
                        Rules
                    </div>
                </div>
            ),
            width: 100,
            render: (_, i) => (
                <div title={getVerifiedRulesNames(i?.verifiedRules)}>
                    {getVerifiedRulesNames(i?.verifiedRules)}
                </div>
            ),
        },
        {
            key: 'initialPsp',
            title: (
                <div className="flex justify-center">
                    <div className="text-xs tracking-tight font-poppins-medium">
                        Initial PSP
                    </div>
                </div>
            ),
            width: 100,
            render: (_, i) => (
                <div className="capitalize">
                    {getPSPNameByValue(i?.initialPsp)}
                </div>
            ),
        },
        {
            key: 'finalPsp',
            title: (
                <div className="flex justify-center">
                    <div className="text-xs tracking-tight font-poppins-medium">
                        Final PSP
                    </div>
                </div>
            ),
            width: 100,
            render: (_, i) => (
                <div className="capitalize">
                    {getPSPNameByValue(i?.finalPsp)}
                </div>
            ),
        },
        {
            key: 'transactionBaseAmountConverted',
            title: (
                <div className="flex justify-center">
                    <div className="text-xs tracking-tight font-poppins-medium">
                        Tx Base
                    </div>
                </div>
            ),
            width: 100,
            render: (_, i) => (
                <div>
                    {formatNumber(Number(i?.transactionBaseAmountConverted))}
                </div>
            ),
        },
        {
            key: 'baseamount',
            title: (
                <div className="flex justify-center">
                    <div className="text-xs tracking-tight font-poppins-medium">
                        Tx Amount
                    </div>
                </div>
            ),
            width: 100,
            render: (_, i) => (
                <div>
                    {i?.pspType === 'TRUST' || i?.pspType === 'PAYMODUM'
                        ? formatNumber(Number(i?.baseamount))
                        : formatNumber(Number(i.amount))}
                </div>
            ),
        },
        {
            key: 'userBalance',
            title: (
                <div className="flex justify-center">
                    <div className="text-xs tracking-tight font-poppins-medium">
                        User Balance
                    </div>
                </div>
            ),
            width: 100,
            render: (_, i) => (
                <div className="flex items-center justify-center">
                    {i?.userBalance ?? '-'}
                </div>
            ),
        },
        {
            key: 'updatedAt',
            title: (
                <div className="flex justify-center">
                    <div className="text-xs tracking-tight font-poppins-medium">
                        Update Date
                    </div>
                </div>
            ),
            width: 100,
            render: (_, i) => (
                <div>{getDateFormats(i?.updatedAt as string)}</div>
            ),
        },
        {
            key: 'currency',
            title: (
                <div className="flex justify-center">
                    <div className="text-xs tracking-tight font-poppins-medium">
                        Currency
                    </div>
                </div>
            ),
            width: 100,
            render: (_, i) => <div>{i?.currency}</div>,
        },
        {
            key: 'email',
            title: (
                <div className="flex justify-center">
                    <div className="text-xs tracking-tight font-poppins-medium">
                        User Email
                    </div>
                </div>
            ),
            width: 100,
            render: (_, i) => <div>{i?.email ?? '-'}</div>,
        },
        {
            key: 'country',
            title: (
                <div className="text-xs tracking-tight font-poppins-medium">
                    <div>User Country</div>
                </div>
            ),
            width: 100,
            render: (_, i) => (
                <div className="capitalize">
                    {i?.country?.toLowerCase() ?? '-'}
                </div>
            ),
        },
        {
            key: 'kycVerified',
            title: (
                <div className="text-xs tracking-tight font-poppins-medium">
                    <div>KYC Status</div>
                </div>
            ),
            width: 100,
            render: (_, i) => (
                <div>{i.kycVerified ? 'Verified' : 'Not Verified'}</div>
            ),
        },
        {
            key: 'blocked',
            title: (
                <div className="text-xs tracking-tight font-poppins-medium">
                    <div>Blocked</div>
                </div>
            ),
            width: 100,
            render: (_, i) => <div>{i.blocked ? 'True' : 'False'}</div>,
        },
        {
            key: 'partnerName',
            title: (
                <div className="text-xs tracking-tight font-poppins-medium">
                    <div>Partner Name</div>
                </div>
            ),
            width: 100,
        },
        {
            key: 'address',
            title: (
                <div className="text-xs tracking-tight font-poppins-medium">
                    <div>Address</div>
                </div>
            ),
            width: 100,
            render: (_, i) => (
                <div>
                    {String(i?.address?.line1 || '') +
                        String(i?.address?.line2 || '-')}
                </div>
            ),
        },
        {
            key: 'city',
            title: (
                <div className="text-xs tracking-tight font-poppins-medium">
                    <div>City</div>
                </div>
            ),
            width: 100,
            render: (_, i) => <div>{i?.address?.city || '-'}</div>,
        },
        {
            key: 'state',
            title: (
                <div className="text-xs tracking-tight font-poppins-medium">
                    <div>State</div>
                </div>
            ),
            width: 100,
            render: (_, i) => <div>{i?.address?.state || '-'}</div>,
        },
        {
            key: 'zipcode',
            title: (
                <div className="text-xs tracking-tight font-poppins-medium">
                    <div>Zip Code</div>
                </div>
            ),
            width: 100,
            render: (_, i) => (
                <div>
                    {i?.card?.billingAddress?.postalCode ??
                        i?.card?.billingAddress?.postcode ??
                        i?.address?.postalCode ??
                        '-'}
                </div>
            ),
        },
        {
            key: 'firstName',
            title: (
                <div className="text-xs tracking-tight font-poppins-medium">
                    <div>User Name</div>
                </div>
            ),
            width: 100,
            render: (_, i) => (
                <div>{`${i?.firstName as string} ${
                    i?.lastName as string
                }`}</div>
            ),
        },
        {
            key: 'phone',
            title: (
                <div className="text-xs tracking-tight font-poppins-medium">
                    <div>Phone Number</div>
                </div>
            ),
            width: 100,
        },
        {
            key: 'gender',
            title: (
                <div className="text-xs tracking-tight font-poppins-medium">
                    <div>Gender</div>
                </div>
            ),
            width: 100,
        },
    ];
};

export const headerList: Array<IHeaderList> = [
    { value: 'All', key: 'all', isChecked: false },
    { value: 'checked', key: 'checked', isChecked: true },
    { value: 'Method', key: 'method', isChecked: true },
    { value: 'Transaction', key: '_id', isChecked: true },
    { value: 'Created', key: 'createdAt', isChecked: true },
    { value: 'Type', key: 'type', isChecked: true },
    { value: 'Auth Status', key: 'authStatus', isChecked: true },
    { value: 'Settle Status', key: 'settleStatus', isChecked: true },
    { value: 'Initial PSP', key: 'initialPsp', isChecked: true },
    { value: 'Rules', key: 'verifiedRules', isChecked: true },
    { value: 'Final PSP', key: 'finalPsp', isChecked: true },
    {
        value: 'Tx Amount',
        key: 'transactionBaseAmountConverted',
        isChecked: true,
    },
    {
        value: 'Tx Base',
        key: 'baseamount',
        isChecked: true,
    },
    { value: 'User Balance', key: 'userBalance', isChecked: true },
    { value: 'Update Date', key: 'updatedAt', isChecked: true },
    { value: 'Currency', key: 'currency', isChecked: true },
    { value: 'User Email', key: 'email', isChecked: true },
    { value: 'User Country', key: 'country', isChecked: true },
    { value: 'KYC Status', key: 'kycVerified', isChecked: false },
    { value: 'Blocked', key: 'blocked', isChecked: false },
    { value: 'Partner Name', key: 'partnerName', isChecked: false },
    { value: 'Address', key: 'address', isChecked: false },
    { value: 'City', key: 'city', isChecked: false },
    { value: 'State', key: 'state', isChecked: false },
    { value: 'Zip Code', key: 'zipcode', isChecked: false },
    { value: 'User Name', key: 'firstName', isChecked: false },
    { value: 'Phone Number', key: 'phone', isChecked: false },
    { value: 'Gender', key: 'gender', isChecked: false },
];
