import React, { useState } from 'react';
import { Dropdown, SvgIcon } from 'components/Common';
import useOutsideClick from 'hooks/useOutsideClick';
import { ICardDetailsProps } from '../../../../Billing.types';

const CardDetails = ({
    setActiveCard,
    activeCard,
    cardNumber,
    expiry,
    _id,
    name,
    reset,
    cvv,
    setUpdateCardId,
}: ICardDetailsProps) => {
    const [openDropDown, setOpenDropDown] = useState(false);
    const dropdownRef = useOutsideClick(
        () => setOpenDropDown(false),
        openDropDown
    );

    const handleMenuClick = (e: any) => {
        if (e === 'Update') {
            setUpdateCardId(_id);
            reset({
                cardNumber: String(cardNumber),
                expiry,
                cvv,
                cardHolderName: name,
            });
        }
        setOpenDropDown(!openDropDown);
    };

    return (
        <div className="border relative rounded-xl px-4 py-3 border-solid border-[#E3E3E3]">
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-x-5">
                    {activeCard === _id ? (
                        <SvgIcon icon="ACTIVE_RADIO_BUTTON" className="!h-5" />
                    ) : (
                        <SvgIcon
                            icon="INACTIVE_RADIO_BUTTON"
                            onClick={() => setActiveCard(_id)}
                            className="!h-5 cursor-pointer"
                        />
                    )}
                    <div>
                        <div className="text-base">{name}</div>
                        <div className="flex items-center gap-x-2">
                            <div className="text-xs">{cardNumber}</div>
                            <div className="text-xs">Expiry: {expiry}</div>
                        </div>
                    </div>
                </div>
                <div ref={dropdownRef}>
                    {openDropDown && (
                        <div className="top-10 absolute right-[162px] z-[100]">
                            <Dropdown
                                submenus={[
                                    {
                                        title: 'Update',
                                        id: 2,
                                        icon: '',
                                    },
                                    {
                                        title: 'Remove',
                                        id: 1,
                                        icon: '',
                                    },
                                ]}
                                handleClick={handleMenuClick}
                                dropdown={openDropDown}
                            />
                        </div>
                    )}
                    <div>
                        <SvgIcon
                            className="cursor-pointer"
                            icon="MENU_THREE_DOT"
                            onClick={() => {
                                setOpenDropDown(!openDropDown);
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default CardDetails;
