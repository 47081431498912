import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import classNames from 'classnames';
import { Drawer, PrimaryButton, Table } from 'components/Common';
import { IGroup, IPermissionData } from 'data/common';
import {
    getAllBrands,
    getAllPageAreas,
    getGroupData,
    getPermissions,
    inviteUser,
} from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { IGetAllBrandData, IGetAllPageAreasData } from 'data/types/response';
import { useAuthContext } from 'context/Auth/AuthContext';
import LoadingSpinner from 'components/Common/Loader/Loader';
import { AppActionsEnum } from 'context/Auth/AuthContextValues';
import {
    UserDrawerAccessTableColumns,
    UserDrawerPageAreaTableColumns,
} from './UserDrawerTableUtill';
import { UserDrawerForm } from './UserDrawerForm';
import { IUserDrawerProps, IUserType } from '../User.type';
import { formSchemaValidation } from '../User.schema';
import { useCompanyOptionList, useFetchCompanies } from '../../useAdminCompany';

export const AddUserDrawer = ({
    isOpen,
    openDrawer,
    userData,
    setPageAreaData,
    pageAreaData,
    accessAreaData,
    setAccessAreaData,
    getUsers,
    setIsOpen,
}: IUserDrawerProps) => {
    const { auth, dispatch } = useAuthContext();

    const [userLoading, setUserLoading] = useState<boolean>(false);
    const [pageAreaLoading, setPageAreaLoading] = useState<boolean>(false);
    const [brandLoading, setBrandLoading] = useState<boolean>(false);
    const [allBrandData, setAllBrandData] = useState<IGetAllBrandData[] | null>(
        null
    );
    const [selectedGroup, setSelectedGroup] = useState<IGroup | null>(null);
    const [permissionsData, setPermissionsData] =
        useState<IPermissionData | null>(null);
    const [allPageAreaData, setAllPageAreaData] = useState<
        IGetAllPageAreasData[] | null
    >(null);
    const [groups, setGroups] = useState<IGroup[]>([]);
    const [filteredPageAreaData, setFilteredPageAreaData] = useState<
        IGetAllPageAreasData[] | null
    >(null);
    const [isLoading, setIsLoading] = useState(false);
    const { allCompanies } = useFetchCompanies({ isOpen, auth });
    const companyOptionList = useCompanyOptionList({ auth, allCompanies });
    const { control, handleSubmit, reset, watch } = useForm<IUserType>({
        resolver: zodResolver(
            formSchemaValidation(auth.isSuperAdmin, auth.isAdmin)
        ),
        defaultValues: {},
    });

    useEffect(() => {
        if (watch('role') === 'USER') {
            const filteredData = allPageAreaData?.filter(
                (item) => item?.name !== 'User Management'
            );
            if (filteredData) {
                const userManagementPageId = allPageAreaData?.find(
                    (item) => item?.name === 'User Management'
                )?._id;
                setPageAreaData(
                    pageAreaData?.filter(
                        (item) => item?.pageAccessId !== userManagementPageId
                    )
                );
                setFilteredPageAreaData(filteredData);
            }
        } else {
            setFilteredPageAreaData(allPageAreaData);
        }
    }, [watch('role'), allPageAreaData]);

    useEffect(() => {
        const companyId = auth.isSuperAdmin
            ? watch('company')
            : auth?.selectedCompany?._id;

        if (auth.groups && auth.groups.length > 0) {
            const filterGroup = auth.groups.filter(
                (v) => v.companyId === companyId && !v.isDeleted
            );
            setGroups?.(filterGroup);
        } else {
            if (!isOpen) return;
            getGroupData({
                companyId,
            })
                .then((res) => {
                    dispatch({
                        type: AppActionsEnum.SET_GROUPS,
                        payload: {
                            groups: res?.groups,
                        },
                    });

                    const activeGroups = res?.groups?.filter(
                        (v) => v.isDeleted
                    );
                    setGroups?.(activeGroups);
                })
                .catch((err) => {
                    showToast(
                        err?.errors?.[0]?.message || 'something went wrong',
                        'error'
                    );
                });
        }
    }, [
        watch('company'),
        auth.groups,
        auth.selectedCompany,
        auth.isSuperAdmin,
        isOpen,
    ]);

    const getGroupPermissions = () => {
        getPermissions(userData?.permissionId ?? '')
            .then((result) => {
                setPermissionsData(result);
                if (setPageAreaData) {
                    setPageAreaData(result?.permission?.pageAreasPermissions);
                }
                if (setAccessAreaData) {
                    setAccessAreaData(result?.permission?.brandsPermissions);
                }
            })
            .catch((err) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    const getPageAreaAccess = async () => {
        await getAllPageAreas()
            .then((result) => {
                dispatch({
                    type: AppActionsEnum.SET_PAGE_AREA_PERMISSIONS,
                    payload: {
                        pageAreaPermissions: result?.pageAreas,
                    },
                });
                setAllPageAreaData(result?.pageAreas);
                setFilteredPageAreaData(result?.pageAreas);
                setPageAreaLoading(false);
            })
            .catch((err) => {
                setPageAreaLoading(false);
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    useEffect(() => {
        if (!isOpen) return;
        setPageAreaLoading(true);
        setBrandLoading(true);
        if (userData?.permissionId) {
            getGroupPermissions();
        }
        if (auth.brandsList && auth.brandsList.length > 0) {
            if (auth.isSuperAdmin) {
                const filteredCompanyData = auth.brandsList.filter(
                    (v: { companyId: string }) =>
                        v.companyId === watch('company')
                );
                setAllBrandData(filteredCompanyData);
            } else {
                const filteredCompanyData = auth.brandsList.filter(
                    (v: { companyId: string }) =>
                        v.companyId === auth?.selectedCompany?._id
                );
                setAllBrandData(filteredCompanyData);
            }
            setBrandLoading(false);
        } else {
            getAllBrands(
                !watch('company')
                    ? {}
                    : {
                          filter: {
                              companyId: watch('company'),
                          },
                      }
            )
                .then((result) => {
                    dispatch({
                        type: AppActionsEnum.SET_BRANDS_LIST,
                        payload: {
                            brandsList: result?.brands,
                        },
                    });
                    setAllBrandData(result?.brands);
                    setBrandLoading(false);
                })
                .catch((err) => {
                    setBrandLoading(false);
                    showToast(
                        err?.errors?.[0]?.message || 'something went wrong',
                        'error'
                    );
                });
        }

        if (auth.pageAreaPermissions && auth.pageAreaPermissions.length > 0) {
            setAllPageAreaData(auth.pageAreaPermissions);
            setFilteredPageAreaData(auth.pageAreaPermissions);
            setPageAreaLoading(false);
        } else {
            getPageAreaAccess();
        }
    }, [isOpen, selectedGroup, watch('company')]);

    const isDisabled = useMemo(() => {
        if (watch('group') !== '2') {
            return true;
        }
        if (watch('group') === '2') {
            return false;
        }
        if (watch('group') === undefined) {
            if (setAccessAreaData) {
                setAccessAreaData([]);
            }
            if (setPageAreaData) {
                setPageAreaData([]);
            }
            return true;
        }

        return false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch('group')]);

    useEffect(() => {
        if (watch('group') === '2') {
            if (setPageAreaData) {
                setPageAreaData([]);
            }
            if (setAccessAreaData) {
                setAccessAreaData([]);
            }
        }
    }, [watch('group')]);

    const handleOnSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value === '2') {
            setPermissionsData(null);
            if (setAccessAreaData) {
                setAccessAreaData([]);
            }
            if (setPageAreaData) {
                setPageAreaData([]);
            }
        }
        const group = groups?.find((item) => item._id === e.target.value);
        if (e.target.value === '1') {
            if (setAccessAreaData) {
                setAccessAreaData([]);
            }
            if (setPageAreaData) {
                setPageAreaData([]);
            }
        }

        setSelectedGroup(group as IGroup);
    };

    const onSubmit = handleSubmit((formData) => {
        if (!formData) return;
        setIsLoading(true);
        const payload = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            name: `${formData.firstName} ${formData.lastName}`,
            email: formData.email,
            phone: formData.phone,
            job: formData.job,
            role: formData.role,
            brandsPermissions:
                watch('group') === '2' || watch('group') === null
                    ? accessAreaData
                    : selectedGroup?.permission?.brandsPermissions,
            pageAreasPermissions:
                watch('group') === '2' || watch('group') === null
                    ? pageAreaData
                    : selectedGroup?.permission?.pageAreasPermissions,

            company: auth?.isAdmin
                ? auth?.authUser?.company ?? ''
                : formData?.company ?? '',
        };

        if (!payload.brandsPermissions?.length) {
            showToast('Please select at least one brand access', 'error');
            setIsLoading(false);
            return;
        }

        inviteUser(
            watch('group') === '2'
                ? { ...payload, isCustomGroup: true }
                : {
                      ...payload,
                      isCustomGroup: false,
                      group: formData?.group,
                  }
        )
            .then((result) => {
                if (result?.errors?.length) {
                    showToast(result.errors[0].msg);
                }
                getUsers();
                reset({});
                setUserLoading(false);
                setIsOpen(false);
                showToast(result?.message, 'success');
            })
            .catch((err) => {
                setUserLoading(false);
                showToast(
                    err?.errors?.[0]?.msg ||
                        err.message ||
                        'you can not invite',
                    'error'
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    });

    useEffect(() => {
        if (!isOpen) return;
        return () => {
            setSelectedGroup(null);
            reset({});
        };
    }, [reset, isOpen]);

    return (
        <Drawer
            drawerSize={400}
            isOpen={isOpen}
            toggleDrawer={() => openDrawer()}
            drawerDirection="right"
            className={classNames(
                'bg-pink-100  max-h-[100vh] relative',
                userLoading ? '' : 'overflow-y-scroll'
            )}
        >
            <form onSubmit={onSubmit}>
                <div className="p-5">
                    <UserDrawerForm
                        companyOptionList={companyOptionList}
                        groups={groups}
                        handleOnSelect={handleOnSelect}
                        openDrawer={() => openDrawer()}
                        userData={userData}
                        control={control}
                        watch={watch}
                    />
                    <div className="mt-3">
                        <div>
                            <div>
                                {brandLoading ? (
                                    <LoadingSpinner />
                                ) : (
                                    <Controller
                                        name="brandsPermissions"
                                        control={control}
                                        render={({
                                            field: {
                                                onChange,
                                                value,
                                                ...field
                                            },
                                            fieldState,
                                        }) => {
                                            return (
                                                <>
                                                    <Table
                                                        colSpan={3}
                                                        isSidebarTable
                                                        data={
                                                            allBrandData || []
                                                        }
                                                        columns={UserDrawerAccessTableColumns(
                                                            isDisabled,
                                                            selectedGroup,
                                                            accessAreaData,
                                                            permissionsData,
                                                            setAccessAreaData
                                                        )}
                                                        className="w-full"
                                                        {...field}
                                                    />
                                                    <div className="mb-1 text-sm font-medium text-red-200">
                                                        {fieldState.error
                                                            ?.message
                                                            ? fieldState.error
                                                                  ?.message
                                                            : ''}
                                                    </div>
                                                </>
                                            );
                                        }}
                                    />
                                )}
                            </div>
                            <div>
                                {pageAreaLoading ? (
                                    <LoadingSpinner />
                                ) : (
                                    <div>
                                        <Controller
                                            name="brandsPermissions"
                                            control={control}
                                            render={({
                                                field: {
                                                    onChange,
                                                    value,
                                                    ...field
                                                },
                                                fieldState,
                                            }) => {
                                                return (
                                                    <>
                                                        <Table
                                                            isSidebarTable
                                                            data={
                                                                filteredPageAreaData ||
                                                                []
                                                            }
                                                            columns={UserDrawerPageAreaTableColumns(
                                                                isDisabled,
                                                                selectedGroup,
                                                                pageAreaData,
                                                                permissionsData,
                                                                setPageAreaData
                                                            )}
                                                            {...field}
                                                            className="w-full"
                                                            colSpan={3}
                                                        />
                                                        <div className="mb-1 text-sm font-medium text-red-200">
                                                            {fieldState.error
                                                                ?.message
                                                                ? fieldState
                                                                      .error
                                                                      ?.message
                                                                : ''}
                                                        </div>
                                                    </>
                                                );
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sticky bottom-0 z-10 mt-2 flex bg-white p-5">
                    <PrimaryButton
                        type="submit"
                        isDrawerButton
                        className="w-full"
                        color="#2E672F"
                        variant="filled"
                        name={isLoading ? '' : 'Create new user'}
                        loading={isLoading}
                        disabled={isLoading}
                    />
                </div>
            </form>
        </Drawer>
    );
};
