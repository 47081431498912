import { Dispatch, FormEventHandler, SetStateAction } from 'react';
import { ICompanyData } from 'data/common';
import { Control } from 'react-hook-form';
import { BulkActionTypes } from 'data/constants/common.constants';

export interface ICompaniesDrawerProps {
    isOpen: boolean;
    openDrawer: () => void;
    setCompany: Dispatch<SetStateAction<ICompanyData | null>>;
    isEditCompany?: boolean;
    company?: ICompanyData | null;
    getAllCompanies: (val1: string, val2: string) => void;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export interface ICompaniesHeaderProps {
    openDrawer: () => void;
    getAllCompanies: () => void;
    checkedCompanies: Array<string>;
    setCheckedCompanies: Dispatch<SetStateAction<Array<string>>>;
}

export interface CompaniesTableProps {
    setCompany: Dispatch<SetStateAction<ICompanyData | null>>;
    setIsEditCompany: Dispatch<SetStateAction<boolean>>;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    allCompanies: Array<ICompanyData>;
    setAllCompanies: Dispatch<SetStateAction<Array<ICompanyData>>>;
    checkedCompanies: Array<string>;
    setCheckedCompanies: Dispatch<SetStateAction<Array<string>>>;
}

export interface ICompanyProps {
    companyName: string;
    billingCurrency: string;
}

export interface ICompaniesForm {
    handleSubmit: FormEventHandler<HTMLFormElement>;
    control: Control<ICompanyProps>;
    companyLoading: boolean;
    company: ICompanyData | null | undefined;
    isEditCompany?: boolean;
}

export const Options = [
    { id: '', name: BulkActionTypes.BULK_ACTION },
    { id: 'disable', name: BulkActionTypes.DISABLE },
];

export interface CompaniesTableParams {
    isAllChecked: boolean;
    setCheckedCompanies: Dispatch<SetStateAction<string[]>>;
    checkedCompanies: Array<string>;
    allCompanies: Array<ICompanyData>;
    setCompany: Dispatch<SetStateAction<ICompanyData | null>>;
    setIsEditCompany: Dispatch<SetStateAction<boolean>>;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    setSelectedCompany: Dispatch<SetStateAction<ICompanyData | null>>;
    setIsConfirmationModal: Dispatch<SetStateAction<boolean>>;
}
