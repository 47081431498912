import React, { useEffect, useState } from 'react';
import { BILLING_INVOICE_CURRENCY } from 'data/constants/payment.constants';
import { useAuthContext } from 'context/Auth/AuthContext';
import PlanCard from './common/PlanCard/PlanCard';
import {
    IPlanData,
    IPlansProps,
    PLAN_DURATIONS_KEYS,
    PLAN_DURATIONS_LABEL,
} from '../Billing.types';
import AddPaymentCardModal from './modals/AddPaymentCardModal/AddPaymentCardModal';
import PaymentStatusModal from './modals/PaymentStatusModal/PaymentStatusModal';
import EditablePlanCard from './common/EditablePlanCard/EditablePlanCard';
import { PrimaryButton, SvgIcon } from '../../Common';

const Plans = ({
    plans,
    invoiceData,
    getInvoiceData,
    fetchPlansData,
}: IPlansProps) => {
    const [duration, setDuration] = useState<string>(
        localStorage.getItem('duration') ?? PLAN_DURATIONS_KEYS.MONTH
    );
    const [filteredPlans, setFilteredPlans] = useState<IPlanData[]>([]);
    const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
    const [plansCollapsed, setPlansCollapsed] = useState(false);
    const [addPaymentCardModal, setAddPaymentCardModal] = useState(false);
    const [paymentStatusModal, setPaymentStatusModal] = useState(false);
    const [selectedPlanData, setSelectedPlanData] = useState<IPlanData | null>(
        null
    );
    const [activeEditPlanId, setActiveEditPlanId] = useState<string>('');
    const { auth } = useAuthContext();

    useEffect(() => {
        const filteredData = plans.filter((ele) => ele.unit === duration);
        setFilteredPlans(filteredData);
    }, [plans, duration]);

    const handleDuration = (key: PLAN_DURATIONS_KEYS) => {
        localStorage.setItem('duration', key);
        setDuration(key);
        const filteredData = plans.filter((ele) => ele.unit === key);
        setFilteredPlans(filteredData);
    };

    useEffect(() => {
        // const selectedCompany = auth?.companiesAndBrands?.find(
        //     (ele: any) => ele._id === auth?.selectedCompany?._id
        // );
        if (auth?.isSuperAdmin) return;
        const filteredData = plans.filter(
            (ele) => ele.currency === auth?.authUser?.billingCurrency
        );

        setFilteredPlans(filteredData);
    }, [plans, auth?.authUser?.billingCurrency]);

    const getButtonStyle = (key: PLAN_DURATIONS_KEYS) => {
        if (key === duration) {
            return '!rounded-full !px-4 !h-9 !text-xs font-merri-bold';
        }
        return '!bg-white !text-[#2E672F] !rounded-full !px-4 !h-9 !text-xs font-merri-bold';
    };

    useEffect(() => {
        if (selectedPlan) {
            const planData = plans.find((ele) => ele._id === selectedPlan);
            if (planData) {
                setSelectedPlanData(planData);
            }
        }
    }, [selectedPlan]);

    useEffect(() => {
        setSelectedPlan(null);
        setSelectedPlanData(null);
    }, [invoiceData]);

    // const isWrite = usePermission({
    //     checkPermission: PageIdentifiers.BILLING_INFORMATION,
    // });

    return (
        <div>
            <div className="my-4 flex items-center justify-between gap-x-6">
                <div className="flex items-center justify-between gap-x-6">
                    {invoiceData.length > 0 ? (
                        <button
                            type="button"
                            aria-label="colllapse"
                            onClick={() => setPlansCollapsed(!plansCollapsed)}
                            className="flex cursor-pointer items-center gap-x-4 border-none bg-transparent px-0"
                        >
                            <SvgIcon
                                icon={
                                    plansCollapsed ? 'ARROW_UP' : 'ARROW_DOWN'
                                }
                                className="stroke-black pb-0.5"
                            />

                            <div className="text-base font-poppins-bold">
                                Price Plan
                            </div>
                        </button>
                    ) : (
                        <div className="text-base font-poppins-bold">
                            Price Plan
                        </div>
                    )}
                </div>
                <div className="flex bg-white w-fit rounded-full justify-between px-1 py-1 border border-solid border-[#00000014]">
                    <PrimaryButton
                        color="#2E672F"
                        variant="filled"
                        name={PLAN_DURATIONS_LABEL.MONTHLY}
                        onClick={() =>
                            handleDuration(PLAN_DURATIONS_KEYS.MONTH)
                        }
                        className={`${getButtonStyle(
                            PLAN_DURATIONS_KEYS.MONTH
                        )}`}
                    />
                    <PrimaryButton
                        color="#2E672F"
                        variant="filled"
                        name={PLAN_DURATIONS_LABEL.YEARLY}
                        onClick={() => handleDuration(PLAN_DURATIONS_KEYS.YEAR)}
                        className={`${getButtonStyle(
                            PLAN_DURATIONS_KEYS.YEAR
                        )}`}
                    />
                </div>
            </div>
            {!plansCollapsed && (
                <div className="rounded-3xl bg-white p-6">
                    <div className="flex flex-wrap justify-center gap-8">
                        {filteredPlans.map((ele) =>
                            activeEditPlanId === ele._id ? (
                                <EditablePlanCard
                                    fetchPlansData={fetchPlansData}
                                    duration={duration}
                                    key={ele._id}
                                    defaultCurrency={
                                        ele.currency as BILLING_INVOICE_CURRENCY.USD
                                    }
                                    defaultFeatureList={ele.features}
                                    defaultIsAdded
                                    defaultPlanName={ele.name}
                                    defaultPrice={ele.price}
                                    onCancel={() => setActiveEditPlanId('')}
                                    planId={ele._id}
                                />
                            ) : (
                                <PlanCard
                                    key={ele._id}
                                    {...ele}
                                    selectedPlan={selectedPlan}
                                    setSelectedPlan={setSelectedPlan}
                                    getInvoiceData={getInvoiceData}
                                    fetchPlansData={fetchPlansData}
                                    setActiveEditPlanId={setActiveEditPlanId}
                                    isEditable={auth?.isSuperAdmin}
                                />
                            )
                        )}
                        {auth?.isSuperAdmin && (
                            <EditablePlanCard
                                fetchPlansData={fetchPlansData}
                                duration={duration}
                            />
                        )}
                    </div>
                </div>
            )}

            {selectedPlan &&
                !auth?.isSuperAdmin &&
                !plansCollapsed &&
                filteredPlans?.some((plan) => plan?._id === selectedPlan) && (
                    <PrimaryButton
                        color="#2E672F"
                        name="Continue to payment method"
                        className="w-full !h-12 !text-base !rounded-xl !mt-6"
                        variant="filled"
                        onClick={() => setAddPaymentCardModal(true)}
                    />
                )}

            {addPaymentCardModal && (
                <AddPaymentCardModal
                    setAddPaymentCardModal={setAddPaymentCardModal}
                    setPaymentStatusModal={setPaymentStatusModal}
                    selectedPlanData={selectedPlanData}
                />
            )}
            {paymentStatusModal && (
                <PaymentStatusModal
                    setPaymentStatusModal={setPaymentStatusModal}
                    getInvoiceData={getInvoiceData}
                />
            )}
        </div>
    );
};

export default Plans;
