import React, { useState } from 'react';
import { PrimaryButton, Switch } from 'components/Common';
import ConfirmationModal from 'components/Modal/ConfirmationModal/ConfirmationModal';
import { showToast } from 'data/utils/toast';
import { deletePaymentProvider, updatePaymentProvider } from 'services/api/api';
import './style.css';
import { IAccountManagerFooterProps } from '../../configuration.type';

const AccountManagerFooter = ({
    loading,
    activeAccount,
    disabled,
    accounts,
    setActiveAccount,
    fetchConfigurationList,
}: IAccountManagerFooterProps) => {
    const [isDefaultModal, setIsDefaultModal] = useState(false);
    const [isRemoveModal, setIsRemoveModal] = useState(false);
    const [removeAccountLoading, setRemoveAccountLoading] = useState(false);
    const [isDeactivateModal, setIsDeactivateModal] = useState(false);
    const [updateAccountLoading, setUpdateAccountLoading] = useState(false);

    const handleRemoveAccount = async () => {
        if (accounts.length === 1 || !activeAccount) return;
        const payload = {
            type: activeAccount?.type,
            companyId: activeAccount?.companyId,
        };
        setRemoveAccountLoading(true);
        try {
            await deletePaymentProvider(payload, activeAccount._id);
            showToast('successfully deleted', 'success');
            fetchConfigurationList();
            setActiveAccount(accounts[0]);
        } catch (err: any) {
            showToast(err.message, 'error');
        } finally {
            setRemoveAccountLoading(false);
            setIsRemoveModal(false);
        }
    };

    const handleAccountUpdate = async (key: string, value: boolean) => {
        const payload = {
            type: activeAccount.type,
            companyId: activeAccount.companyId,
            ...activeAccount,
            [key]: value,
        };
        setUpdateAccountLoading(true);
        try {
            await updatePaymentProvider(payload, activeAccount?._id);
            showToast('successfully updated', 'success');

            fetchConfigurationList();
        } catch (err: any) {
            showToast(err.message, 'error');
        } finally {
            setUpdateAccountLoading(false);
            setIsDefaultModal(false);
            setIsDeactivateModal(false);
        }
    };

    return (
        <div className="flex items-center justify-between">
            {isDefaultModal && (
                <ConfirmationModal
                    onConfirmation={() =>
                        handleAccountUpdate(
                            'isDefaultAccount',
                            !activeAccount?.isDefaultAccount
                        )
                    }
                    setIsModalOpen={setIsDefaultModal}
                    isLoading={updateAccountLoading}
                    message={`${
                        activeAccount?.isDefaultAccount
                            ? 'Are you sure you want to remove this account as the default?'
                            : 'Are you sure you want to set this account as the default? Only one account can be the default at a time.'
                    }`}
                    className="!z-[111]"
                />
            )}
            {isRemoveModal && (
                <ConfirmationModal
                    onConfirmation={handleRemoveAccount}
                    setIsModalOpen={setIsRemoveModal}
                    isLoading={removeAccountLoading}
                    message={`Are you sure you want to permanently remove the account "${activeAccount.accountName}"?`}
                    className="!z-[111]"
                />
            )}
            {isDeactivateModal && (
                <ConfirmationModal
                    onConfirmation={() =>
                        handleAccountUpdate(
                            'isDeActivatedAccount',
                            !activeAccount?.isDeActivatedAccount
                        )
                    }
                    setIsModalOpen={setIsDeactivateModal}
                    isLoading={updateAccountLoading}
                    message={`${
                        activeAccount?.isDeActivatedAccount
                            ? 'Are you sure you want to reactivate this account?'
                            : 'Are you sure you want to deactivate this account?'
                    }`}
                    className="!z-[111]"
                />
            )}

            <div className="flex items-center w-full  gap-3">
                <button
                    aria-label="default"
                    type="button"
                    className="flex items-center gap-2 bg-transparent border-none"
                >
                    <span className="text-[#2E672F] text-xs cursor-pointer font-merri-regular bg-transparent border-none">
                        Set as Default
                    </span>
                    <Switch
                        onClick={() => setIsDefaultModal(true)}
                        disabled={
                            activeAccount?.isTestAccount ||
                            activeAccount?.isDeActivatedAccount ||
                            activeAccount?.isDefaultAccount
                        }
                        isToggled={activeAccount?.isDefaultAccount}
                        className="rounded-2xl bg-gray-200 default-switch"
                    />
                </button>
                <button
                    aria-label="deactivate"
                    type="button"
                    className="flex items-center gap-2 bg-transparent border-none"
                >
                    <span className="text-[#2E672F] text-xs font-merri-regular cursor-pointer bg-transparent border-none">
                        Deactivate Account
                    </span>
                    <Switch
                        disabled={activeAccount?.isDefaultAccount}
                        onClick={() => setIsDeactivateModal(true)}
                        isToggled={activeAccount?.isDeActivatedAccount}
                        className="rounded-2xl bg-gray-200 default-switch"
                    />
                </button>
            </div>
            <div className="flex w-full max-w-[372px] items-center gap-x-3">
                <PrimaryButton
                    isDrawerButton
                    className="w-[180px]"
                    color="#8FB131"
                    variant="filled"
                    type="button"
                    disabled={disabled || activeAccount?.isDefaultAccount}
                    name="Remove Account"
                    loading={false}
                    onClick={() => setIsRemoveModal(true)}
                />
                <PrimaryButton
                    isDrawerButton
                    className="w-[180px]"
                    color="#2E672F"
                    variant="filled"
                    type="submit"
                    name={loading ? '' : 'Save and Update'}
                    loading={loading}
                    disabled={loading}
                />
            </div>
        </div>
    );
};

export default AccountManagerFooter;
