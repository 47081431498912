import React, { useEffect, useState } from 'react';
import { PrimaryButton } from 'components/Common';
import { getJwtPaymentToken, makePayment } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import './AddCardForm.css';
import { BILLING_INVOICE_STATUS, IAddCardFormProps } from '../../Billing.types';

const AddCardForm = ({
    selectedPlanData,
    setAddPaymentCardModal,
    setPaymentStatusModal,
}: IAddCardFormProps) => {
    const [jwtToken, setJwtToken] = useState(null);

    const fetchJwtToken = async () => {
        if (!selectedPlanData) return;
        try {
            const response = await getJwtPaymentToken({
                currencyiso3a: selectedPlanData?.currency,
                baseamount: selectedPlanData?.price,
                orderreference: '59-9-4115672',
                accounttypedescription: 'ECOM',
                requesttypedescriptions: ['THREEDQUERY', 'AUTH'],
            });
            setJwtToken(response?.token);
        } catch (error: any) {
            showToast(error?.message, 'error');
        }
    };

    useEffect(() => {
        fetchJwtToken();
    }, []);

    const generateInvoice = async (response: any) => {
        if (!selectedPlanData) return;
        const payload = {
            planId: selectedPlanData?._id,
            amount: selectedPlanData?.price,
            status: BILLING_INVOICE_STATUS.PAID,
            currency: selectedPlanData?.currency,
            paymentDetails: response,
        };
        try {
            await makePayment(payload);
        } catch (error: any) {
            showToast(error?.message, 'error');
        }
    };

    useEffect(() => {
        if (!jwtToken) return;
        const script = document.createElement('script');
        script.src = 'https://cdn.eu.trustpayments.com/js/latest/st.js';
        script.async = true;
        script.onload = () => {
            try {
                if (window.SecureTrading) {
                    const st = window.SecureTrading({
                        jwt: jwtToken,
                        buttonId: 'payment-button',
                        formId: 'my-checkout-form',
                        placeholders: {
                            pan: '0000 0000 0000 0000',
                            expirydate: 'MM / YY',
                            securitycode: '***',
                        },
                        translations: {
                            'Invalid response': 'Invalid Jwt Token',
                        },
                        submitOnSuccess: false,
                        submitCallback: (response: any) => {
                            if (
                                response?.errormessage &&
                                response?.errorcode !== '0'
                            ) {
                                showToast(response.errormessage, 'error');
                            } else {
                                showToast(response.errormessage, 'success');
                                setAddPaymentCardModal(false);
                                setPaymentStatusModal(true);
                                generateInvoice(response);
                            }
                        },
                        styles: {
                            'font-size-label': '14px',
                            'color-input': '#000000',
                            'font-weight-label': 'bold',
                            'space-inset-wrapper': '4px 0',
                            'border-radius-input': '8px',
                            'border-size-input': '2px',
                            'border-color-input': '#818181',
                            'space-inset-input': '14px 8px',
                        },
                    });
                    st.Components();
                }
            } catch (error: any) {
                showToast(error?.message || 'Something went wrong', 'error');
            }
        };

        script.onerror = () => {
            showToast('Failed to load payment script', 'error');
        };

        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, [jwtToken]);

    return (
        <form id="my-checkout-form" className="w-full">
            <div className="h-28" id="st-card-number" />
            <div className="grid grid-cols-2 items-center gap-6">
                <div className="h-28" id="st-expiration-date" />
                <div className="h-28" id="st-security-code" />
            </div>
            <PrimaryButton
                color="#2E672F"
                name="Save card and make payment"
                className="w-full !h-12 !text-base !rounded-xl"
                variant="filled"
                type="submit"
                id="payment-button"
            />
        </form>
    );
};

export default AddCardForm;
