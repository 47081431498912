import {
    Modal,
    PrimaryButton,
    PrimaryTextArea,
    Select,
} from 'components/Common';
import { useAuthContext } from 'context/Auth/AuthContext';
import {
    ActionOptions,
    ReasonOptions,
    PAYOUT_ACTION,
    PAYOUT_CANCELLED_OPTIONS,
} from 'data/constants/approve.constants';
import { ActionModalProps } from '../Modal.type';

const ActionModal = ({
    setIsOpen,
    className,
    handleAction,
    actionModalData,
    setActionModalData,
    loading,
}: ActionModalProps) => {
    const { auth } = useAuthContext();

    const handleSubmit = () => {
        if (setActionModalData) {
            setActionModalData({
                status: PAYOUT_ACTION.APPROVED,
                reason: PAYOUT_CANCELLED_OPTIONS.TECHNICAL_ISSUE,
                comment: '',
            });
        }
        setIsOpen(false);
    };

    return (
        <Modal title="Action" classname={className} setIsOpen={setIsOpen} icon>
            <div className="flex flex-col gap-6	p-2 mt-6 pt-0 w-[528px] ">
                <Select
                    value={actionModalData?.status || ''}
                    bordered
                    isGroup
                    className="!bg-white border border-solid border-[#C8C8C8]"
                    options={ActionOptions}
                    label="Status"
                    handleOnSelect={(e) =>
                        setActionModalData &&
                        setActionModalData({
                            ...actionModalData,
                            status: e.target.value,
                        })
                    }
                />

                {actionModalData?.status !== PAYOUT_ACTION.APPROVED && (
                    <>
                        <Select
                            value={actionModalData?.reason || ''}
                            bordered
                            isGroup
                            className="!bg-white border border-solid border-[#C8C8C8]"
                            options={ReasonOptions}
                            label="Reason"
                            handleOnSelect={(e) =>
                                setActionModalData &&
                                setActionModalData({
                                    ...actionModalData,
                                    reason: e.target.value,
                                })
                            }
                        />
                        <PrimaryTextArea
                            value={actionModalData?.comment || ''}
                            className="max-w-[513px]"
                            onChange={(e) =>
                                setActionModalData &&
                                setActionModalData({
                                    ...actionModalData,
                                    comment: e.target.value,
                                })
                            }
                            cols={5}
                            rows={4}
                            placeholder="Comment.."
                        />
                    </>
                )}
                <div className="flex items-start text-sm font-medium">
                    <div>Updated by : </div>
                    <div className="text-[#8FB131] ps-0.5">
                        {auth.authUser?.name}
                    </div>
                </div>
                <div className="flex flex-col gap-3">
                    <PrimaryButton
                        type="submit"
                        color="#2E672F"
                        variant="filled"
                        isDrawerButton
                        name={loading ? '' : 'Save and Update'}
                        loading={loading}
                        disabled={loading}
                        onClick={() => handleAction && handleAction()}
                    />
                    <PrimaryButton
                        type="submit"
                        color="#8FB131"
                        variant="filled"
                        isDrawerButton
                        name="Clear"
                        onClick={handleSubmit}
                    />
                </div>
            </div>
        </Modal>
    );
};
export default ActionModal;
