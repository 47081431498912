import React, { useEffect, useState } from 'react';
import { Modal, PrimaryButton } from 'components/Common';
import PlanCard from '../../common/PlanCard/PlanCard';
import {
    IPaymentPlanModalProps,
    IPlanData,
    PLAN_DURATIONS_KEYS,
    PLAN_DURATIONS_LABEL,
} from '../../../Billing.types';

const PaymentPlanModal = ({
    getInvoiceData,
    plans,
    setUpdatePaymentPlanModal,
    fetchPlansData,
}: IPaymentPlanModalProps) => {
    const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
    const [duration, setDuration] = useState(PLAN_DURATIONS_KEYS.MONTH);
    const [filteredPlans, setFilteredPlans] = useState<IPlanData[]>([]);

    const handleDuration = (key: PLAN_DURATIONS_KEYS) => {
        setDuration(key);
        const filteredData = plans.filter((ele) => ele.unit === key);
        setFilteredPlans(filteredData);
    };

    useEffect(() => {
        const filteredData = plans.filter((ele) => ele.unit === duration);
        setFilteredPlans(filteredData);
    }, [plans, duration]);

    const getButtonStyle = (key: PLAN_DURATIONS_KEYS) => {
        if (key === duration) {
            return '!rounded-full !px-4 !h-9 !text-xs font-merri-bold';
        }
        return '!bg-white !text-[#2E672F] !rounded-full !px-4 !h-9 !text-xs font-merri-bold';
    };

    return (
        <Modal
            classname="w-full max-w-[1300px] !p-8 box-border !rounded-3xl relative"
            setIsOpen={setUpdatePaymentPlanModal}
            isBillingModal
            title="Payment Plan"
        >
            <div>
                <div className="flex absolute right-8 top-8 bg-white w-fit rounded-full justify-between px-1 py-1 border border-solid border-[#00000014]">
                    <PrimaryButton
                        color="#2E672F"
                        variant="filled"
                        name={PLAN_DURATIONS_LABEL.MONTHLY}
                        onClick={() =>
                            handleDuration(PLAN_DURATIONS_KEYS.MONTH)
                        }
                        className={`${getButtonStyle(
                            PLAN_DURATIONS_KEYS.MONTH
                        )}`}
                    />
                    <PrimaryButton
                        color="#2E672F"
                        variant="filled"
                        name={PLAN_DURATIONS_LABEL.YEARLY}
                        onClick={() => handleDuration(PLAN_DURATIONS_KEYS.YEAR)}
                        className={`${getButtonStyle(
                            PLAN_DURATIONS_KEYS.YEAR
                        )}`}
                    />
                </div>
                <div className="bg-white max-h-[384px] overflow-auto rounded-3xl p-6">
                    <div className="flex flex-wrap justify-center items-center gap-8">
                        {filteredPlans.map((ele) => (
                            <PlanCard
                                key={ele._id}
                                {...ele}
                                selectedPlan={selectedPlan}
                                setSelectedPlan={setSelectedPlan}
                                getInvoiceData={getInvoiceData}
                                fetchPlansData={fetchPlansData}
                                setActiveEditPlanId={() => {}}
                                isEditable={false}
                                isChangePlanModal
                            />
                        ))}
                    </div>
                </div>
                <PrimaryButton
                    color="#2E672F"
                    name="Upgrade/downgrade plan"
                    className="w-full !h-12 !mb-2 !text-base !rounded-xl"
                    variant="filled"
                />
                <PrimaryButton
                    color="#2E672F"
                    name="Cancel and stay on current plan"
                    className="w-full !h-12 !mb-2 !text-base !rounded-xl"
                    variant="outline"
                    onClick={() => setUpdatePaymentPlanModal(false)}
                />
                <div className="text-center text-[#131119]">
                    Note: Your new plan will take effect at the end of your
                    current billing cycle.
                </div>
            </div>
        </Modal>
    );
};

export default PaymentPlanModal;
