import React, { useState } from 'react';
import { Handle, Position } from 'reactflow';
import { SvgIcon } from 'components/Common';
import { formatValue } from 'data/utils/common';
import useGetAccounts from 'hooks/useGetAccounts';
import { useAuthContext } from 'context/Auth/AuthContext';
import {
    CustomNodeProps,
    getTargetPos,
    RuleBuilderTabs,
} from '../../RuleEngine.type';

export const CustomNode = ({ data }: CustomNodeProps) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const isAction = data.actionType === RuleBuilderTabs.ACTION;
    const { auth } = useAuthContext();
    const { accounts } = useGetAccounts(auth?.selectedCompany?._id);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const isTarget = (pos: string) => {
        return getTargetPos(data.srcPos) === pos;
    };

    const handlePos = `${getTargetPos(data.srcPos) as string}-${data.id}`;

    function isConnected(position: string) {
        return handlePos === position;
    }

    const handleRuleClose = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        e.stopPropagation();
        if (data.setCloseRuleModal && data.setCloseRuleNode) {
            data.setCloseRuleModal(true);
            data.setCloseRuleNode(data);
        }
    };

    const getAccountLabel = () => {
        if (data.label === 'Account') {
            const selectedAccount = accounts?.find(
                (ele) => ele?._id === data.items
            );
            return selectedAccount?.accountName ?? 'removed account';
        }
        return formatValue(data?.items);
    };

    return (
        <button
            type="button"
            key={data.id}
            className={`w-full bg-transparent min-h-10 px-4 ${
                isAction
                    ? 'min-h-10 border-solid border-[2px] border-[#3EACFA] text-[#3EACFA] rounded'
                    : 'border-solid border-[2px] border-black min-h-10 rounded'
            }`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {data.label} {data?.operation || '='}
            {getAccountLabel()}
            <Handle
                type={isTarget(Position.Right) ? 'target' : 'source'}
                position={Position.Right}
                id={`right-${data.id}`}
                data-isaction={isAction}
                isConnectable
            >
                <div>
                    <SvgIcon
                        icon="ADD_ICON"
                        className="cursor-pointer pointer-events-none relative"
                    />
                    {isHovered && (
                        <div
                            id={data.id}
                            className="!cursor-pointer"
                            onClick={handleRuleClose}
                        >
                            <SvgIcon
                                icon="CROSS_DELETE_ICON"
                                className="absolute right-[50px]"
                            />
                        </div>
                    )}
                </div>
            </Handle>
            <Handle
                type={isTarget(Position.Top) ? 'target' : 'source'}
                position={Position.Top}
                id={`top-${data.id}`}
                data-isaction={isAction}
                isConnectable
                className={
                    isConnected(`top-${data.id}`)
                        ? '!absolute !-top-1 !left-[-2px] !w-0 !min-w-0 !border-none'
                        : ''
                }
            >
                <div>
                    <SvgIcon
                        icon="ADD_ICON"
                        className={`cursor-pointer ${
                            isConnected(`top-${data.id}`) ? '!opacity-0' : ''
                        } pointer-events-none relative`}
                    />
                </div>
            </Handle>
            <Handle
                type={isTarget(Position.Bottom) ? 'target' : 'source'}
                position={Position.Bottom}
                id={`bottom-${data.id}`}
                data-isaction={isAction}
                isConnectable
                className={
                    isConnected(`bottom-${data.id}`)
                        ? '!absolute !top-[60%] !left-[-2px] !w-0 !min-w-0 !border-none'
                        : ''
                }
            >
                <div>
                    <SvgIcon
                        icon="ADD_ICON"
                        className={`cursor-pointer ${
                            isConnected(`bottom-${data.id}`) ? '!opacity-0' : ''
                        } pointer-events-none relative`}
                    />
                </div>
            </Handle>
            <Handle
                type="target"
                position={Position.Left}
                id={`left-${data.id}`}
                isConnectable
                className="!absolute !top-[50%] !left-[-2px] !w-0 !min-w-0 !border-none"
            >
                <div>
                    <SvgIcon
                        icon="ADD_ICON"
                        className="cursor-pointer !opacity-0 pointer-events-none relative"
                    />
                    <div id="draggable" className="!cursor-pointer">
                        <SvgIcon
                            icon="BIG_DRAG_ICON"
                            className="stroke-[#383838] absolute left-1"
                        />
                    </div>
                </div>
            </Handle>
        </button>
    );
};
