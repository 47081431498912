import React from 'react';
import classNames from 'classnames';
import { PrimaryButton, TextField } from 'components/Common';
import { TransactionStatusKeys } from 'data/constants/common.constants';
import { ITransactionHeader, PaymentStatus } from '../Transaction.type';

export const validStatuses = ['0', '1', '2'];

export const TransactionHeader = ({
    setFilterData,
    filterData,
    setIsTableFilter,
    checkedTransaction,
    setIsActionModalOpen,
    selectedRowStatus,
}: ITransactionHeader) => {
    const handleSearchChange = (event: { target: { value: any } }) => {
        const value = event.target.value;

        setFilterData({
            ...filterData,
            search: value?.trim(),
        });
    };

    const handleFilterChange = (value: string) => {
        let updatedStatus: string[];

        if (value === TransactionStatusKeys.ALL_PAYMENTS) {
            updatedStatus = [];
        } else if (filterData?.status.includes(value)) {
            updatedStatus = filterData?.status.filter((item) => item !== value);
        } else {
            updatedStatus = [...filterData.status, value];
        }

        setFilterData({
            ...filterData,
            page: 1,
            status: updatedStatus,
        });
    };

    return (
        <div>
            <div className="flex justify-between">
                <div className="mr-6 ml-1 flex flex-wrap items-center gap-5">
                    <div className="font-merri-bold tracking-tighter text-center text-2xl text-[#131119]">
                        Transactions
                    </div>
                    <TextField
                        iconPosition="left"
                        icon="SEARCH_ICON"
                        isSearchBar
                        className="bg-white	!border-2 !border-solid !rounded-[12px] !border-[#C8C8C8] min-[1480px]:!w-[425px] min-w-[230px] !pl-9"
                        placeholder="Search by tags, filters..."
                        onChange={handleSearchChange}
                        containerStyle="!mb-0"
                    />
                </div>
                <div className="flex flex-wrap items-center gap-5">
                    <PrimaryButton
                        className="!font-extrabold !rounded-xl px-4 !w-24 !text-xs !h-11"
                        color="#8FB131"
                        variant="filled"
                        disabled={
                            checkedTransaction?.length === 0 ||
                            !validStatuses.includes(selectedRowStatus)
                        }
                        name="Action"
                        onClick={() => setIsActionModalOpen((prev) => !prev)}
                    />
                    <PrimaryButton
                        type="button"
                        name="Column Filter"
                        color="#8FB131"
                        variant="filled"
                        isDrawerButton
                        className="!font-extrabold !rounded-xl px-4 !w-24 !text-xs !h-11"
                        onClick={() => setIsTableFilter(true)}
                    />
                </div>
            </div>
            <div className="flex gap-4 pt-4">
                {PaymentStatus.map((v) => (
                    <PrimaryButton
                        key={`keys_${v.value}`}
                        className={classNames(
                            '!text-black !text-xs px-3 !rounded-xl',
                            {
                                '!bg-[#DEECAA]':
                                    (filterData.status.length === 0 &&
                                        v.name === 'All Payments') ||
                                    filterData.status.includes(v.value),
                                '!bg-white':
                                    (v.name !== 'All Payments' &&
                                        !filterData.status.includes(v.value)) ||
                                    (filterData.status.length !== 0 &&
                                        v.name === 'All Payments'),
                            }
                        )}
                        color="#8FB131"
                        variant="filled"
                        name={v.name}
                        onClick={() => handleFilterChange(v.value)}
                    />
                ))}
            </div>
        </div>
    );
};
