import React, {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useMemo,
    useReducer,
    useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
// import Chatra from '@chatra/chatra';
import { getCurrentUser } from 'services/api/api';
import { isAdmin, isSuperAdmin, isUser } from 'data/utils/common';
import {
    AppActions,
    AppActionsEnum,
    AppInitialState,
    IAppContext,
} from './AuthContextValues';

export const AppReducer = (
    state: IAppContext,
    action: AppActions
): IAppContext => {
    const { type, payload } = action;

    console.log('AuthContext action made: ', state, action, {
        ...state,
        ...payload,
    });

    const dynamicHandlers = [
        AppActionsEnum.SET_COMPANY,
        AppActionsEnum.SET_BRAND,
        AppActionsEnum.SET_ACTIVE_GROUP,
        AppActionsEnum.SET_COMPANY_AND_BRANDS,
        AppActionsEnum.SET_PAGE_AREA_PERMISSIONS,
        AppActionsEnum.SET_GROUPS,
        AppActionsEnum.SET_BRANDS_LIST,
        AppActionsEnum.SET_ACCOUNTS,
        AppActionsEnum.SET_COUNTRIES,
        AppActionsEnum.SET_CURRENT_PLAN,
    ];

    if (type === AppActionsEnum.SET_CURRENT_USER) {
        return {
            ...state,
            permissions: payload?.permissions || [],
            brandPermissions: payload?.brandPermissions || [],
            authUser: payload.authUser,
            role: payload.role,
            isLoggedIn: payload.isLoggedIn,
            isAdmin: isAdmin(payload.role),
            isSuperAdmin: isSuperAdmin(payload.role),
            isUser: isUser(payload.role),
            isRegisterUser: payload.isRegisterUser,
        };
    }

    if (dynamicHandlers.includes(type)) {
        return {
            ...state,
            ...payload,
        };
    }

    return state;
};

export interface IAppContextProps {
    auth: IAppContext;
    dispatch: React.Dispatch<AppActions>;
}

const AuthContext = createContext<IAppContextProps>({
    auth: AppInitialState,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    dispatch: () => {},
});

interface AuthContextProviderProps {
    children?: ReactNode;
}
export const AuthContextProvider: React.FC<AuthContextProviderProps> = ({
    children,
}) => {
    const navigate = useNavigate();
    const [auth, dispatch] = useReducer(AppReducer, AppInitialState);
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token') ?? '';
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const fetchCurrentUser = async () => {
        try {
            const currentUser = await getCurrentUser(token);
            if (!currentUser?.user) {
                return dispatch({
                    type: AppActionsEnum.SET_CURRENT_USER,
                    payload: {
                        authUser: null,
                        permissions: [],
                        brandPermissions: [],
                        isLoggedIn: false,
                        role: null,
                    },
                });
            }
            dispatch({
                type: AppActionsEnum.SET_CURRENT_USER,
                payload: {
                    permissions: currentUser.user?.permissions || [],
                    brandPermissions: currentUser.user?.brandPermissions || [],
                    authUser: currentUser.user,
                    isLoggedIn: true,
                    role: currentUser.user.role,
                    isRegisterUser: auth.isRegisterUser,
                },
            });
            setIsLoggedIn(true);
            // const chatraConfig = {
            //     ID: process.env.REACT_APP_CHATRA_PUBLIC_KEY || '',
            //     integration: {
            //         _id: currentUser.user._id,
            //         name: currentUser.user.name,
            //         email: currentUser.user.email,
            //         role: currentUser.role,
            //     },
            // };
            // Chatra('init', chatraConfig);
        } catch (err) {
            console.error('User information could not be fetched', err);
            localStorage.removeItem('token');
            localStorage.removeItem('verifiedToken');
            dispatch({
                type: AppActionsEnum.SET_CURRENT_USER,
                payload: {
                    permissions: [],
                    brandPermissions: [],
                    authUser: null,
                    isLoggedIn: false,
                    role: null,
                    isRegisterUser: false,
                },
            });
            navigate('/login');
        }
    };

    useEffect(() => {
        const authToken = localStorage.getItem('token');
        if (authToken && auth.role && isLoggedIn) return;
        if (authToken) {
            fetchCurrentUser();
        } else {
            dispatch({
                type: AppActionsEnum.SET_CURRENT_USER,
                payload: {
                    brandPermissions: [],
                    permissions: [],
                    authUser: null,
                    isLoggedIn: false,
                    role: null,
                    isRegisterUser: false,
                },
            });
        }
    }, [auth?.isLoggedIn, auth.role]);

    useEffect(() => {
        if (auth?.isLoggedIn) {
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
        }
    }, [auth?.isLoggedIn]);

    const contextValue = useMemo(() => ({ auth, dispatch }), [auth, dispatch]);

    return (
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

// -----------------------------------------------
// We should use this context for anything which needs to be shared across components globally
// and does not match a specific usecase as other contexts.
// -----------------------------------------------

export default AuthContext;

export const useAuthContext = (): IAppContextProps => useContext(AuthContext);
