import { useState } from 'react';
import { Modal, PrimaryButton, Select } from 'components/Common';
import {
    ITransactionActionModal,
    TransactionStatusOptions,
} from 'components/Operations/Transactions/Transaction.type';
import { updateTransactionStatus } from 'services/api/api';
import { showToast } from 'data/utils/toast';

const TransactionActionModal = ({
    setIsOpen,
    checkedTransactions,
    setCheckedTransactions,
    selectedRowStatus,
    fetchTransactionData,
}: ITransactionActionModal) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [status, setStatus] = useState<string>(selectedRowStatus);

    const handleUpdateTransaction = async () => {
        setLoading(true);
        try {
            const payload = { status, transactionId: checkedTransactions?.[0] };
            const response = await updateTransactionStatus(payload);
            showToast(response?.message ?? 'successfully updated', 'success');
            fetchTransactionData();
            setCheckedTransactions([]);
        } catch (err: any) {
            let errMessage = '';
            if (err?.data?.errormessage) {
                errMessage = err?.data?.errormessage;
            } else {
                errMessage = err?.message;
            }
            showToast(errMessage || 'something went wrong', 'error');
        } finally {
            setLoading(false);
            setIsOpen(false);
        }
    };

    return (
        <Modal title="Action" setIsOpen={setIsOpen} icon>
            <div className="flex flex-col gap-6	p-2 mt-6 pt-0 w-[528px]">
                <Select
                    bordered
                    isGroup
                    value={status}
                    onChange={(value) => {
                        setStatus(value.target.value);
                    }}
                    className="!bg-white border border-solid border-[#C8C8C8]"
                    options={TransactionStatusOptions}
                    label="Status"
                />

                <div className="flex flex-col gap-3">
                    <PrimaryButton
                        type="submit"
                        color="#2E672F"
                        variant="filled"
                        isDrawerButton
                        name={loading ? '' : 'Save and Update'}
                        loading={loading}
                        disabled={loading || status === selectedRowStatus}
                        onClick={handleUpdateTransaction}
                    />
                </div>
            </div>
        </Modal>
    );
};
export default TransactionActionModal;
