import React from 'react';
import Modal from 'components/Common/Modal/Modal';
import { PrimaryButton } from 'components/Common';
import AddCardForm from 'components/Billing/common/AddCardForm/AddCardForm';
import { IPlanData } from '../../../Billing.types';

const AddPaymentCardModal = ({
    setAddPaymentCardModal,
    setPaymentStatusModal,
    selectedPlanData,
}: {
    setAddPaymentCardModal: React.Dispatch<React.SetStateAction<boolean>>;
    setPaymentStatusModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedPlanData: IPlanData | null;
}) => {
    return (
        <Modal
            classname="w-[592px] box-border !p-8 !pt-6 !rounded-3xl"
            setIsOpen={setAddPaymentCardModal}
            title="Add Payment Card"
            isBillingModal
            icon
        >
            <div className="mt-5">
                <AddCardForm
                    selectedPlanData={selectedPlanData}
                    setAddPaymentCardModal={setAddPaymentCardModal}
                    setPaymentStatusModal={setPaymentStatusModal}
                />
                <div className="pt-3">
                    <PrimaryButton
                        color="#2E672F"
                        name="Back"
                        type="button"
                        className="w-full !h-12 !text-base !rounded-xl"
                        variant="outline"
                        onClick={() => {
                            setAddPaymentCardModal(false);
                        }}
                    />
                </div>
                <div className="font-poppins-regular pt-4 pb-2 text-[#131119]">
                    Note: This card will be your default payment. Manage cards
                    in your settings.
                </div>
            </div>
        </Modal>
    );
};

export default AddPaymentCardModal;
