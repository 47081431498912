import {
    TransactionStatusKeys,
    TransactionStatusLabels,
} from 'data/constants/common.constants';
import './index.css';
import { StatusBadgeProps } from '../common';

const statusColors: Record<string, string> = {
    [TransactionStatusKeys.SUCCESS]: '#B6FFB6',
    [TransactionStatusKeys.PAID]: '#B6FFB6',
    [TransactionStatusKeys.PENDING]: '#FFF0BB',
    [TransactionStatusKeys.SUSPENDED]: '#EDE7FB',
    [TransactionStatusKeys.FAILED]: '#FCA2C2',
    [TransactionStatusKeys.ERROR]: '#FCA2C2',
    [TransactionStatusKeys.EXPIRED]: '#EDE7FB',
    [TransactionStatusKeys.NOT_VERIFIED]: '#FCA2C2',
    [TransactionStatusKeys.VERIFIED]: '#B6FFB6',
    [TransactionStatusKeys.APPROVE]: '#B6FFB6',
    default: '#EDE7FB',
};

const statusLabels: Record<string, string> = {
    [TransactionStatusKeys.SUCCESS]: TransactionStatusLabels.SUCCESS,
    [TransactionStatusKeys.PAID]: TransactionStatusLabels.PAID,
    [TransactionStatusKeys.PENDING]: TransactionStatusLabels.PENDING,
    [TransactionStatusKeys.SUSPENDED]: TransactionStatusLabels.SUSPENDED,
    [TransactionStatusKeys.FAILED]: TransactionStatusLabels.FAILED,
    [TransactionStatusKeys.ERROR]: TransactionStatusLabels.ERROR,
    [TransactionStatusKeys.EXPIRED]: TransactionStatusLabels.EXPIRED,
    [TransactionStatusKeys.NOT_VERIFIED]: TransactionStatusLabels.NOT_VERIFIED,
    [TransactionStatusKeys.VERIFIED]: TransactionStatusLabels.VERIFIED,
    [TransactionStatusKeys.APPROVE]: TransactionStatusLabels.APPROVE,
};

export const getStatusColor = (status: string) =>
    statusColors[status] || statusColors.default;

export const getStatusLabel = (status: string) =>
    statusLabels[status] || status;

const StatusBadge = ({ status, fullWidth = true }: StatusBadgeProps) => {
    if (!status) return <p>-</p>;
    const formattedStatus =
        status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();

    return (
        <div
            className={`status-badge ${
                fullWidth ? 'w-[100px]' : ''
            } justify-center`}
            style={{ backgroundColor: getStatusColor(status) }}
        >
            <div className="font-poppins-bold tracking-tight text-xs text-center capitalize">
                {formattedStatus.replaceAll('_', ' ')}
            </div>
        </div>
    );
};

export default StatusBadge;
