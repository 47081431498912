import React, { useCallback, useEffect, useState } from 'react';
import { SearchMenuList } from 'components/Common';
import CompanySelectionMessage from 'components/Common/CompanySelectionMessage/CompanySelectionMessage';
import { useAuthContext } from 'context/Auth/AuthContext';
import { showToast } from 'data/utils/toast';
import { getPaymentProvider } from 'services/api/api';
import TrustPaymentForm from './ConfigurationList/TrustPayment';
import SelectProviderPage from './ConfigurationList/SelectProvider';
import { ProvidersList } from './configurationUtills';
import PayModumForm from './ConfigurationList/Paymodum';
import CleoPaymentForm from './ConfigurationList/CleoPayment';
import { ISelectedProvider } from './configuration.type';
import Brite from './ConfigurationList/Brite';

const Configuration = () => {
    const { auth } = useAuthContext();

    const [selectedPspData, setSelectedPspData] = useState({});
    const [pspConfigurationData, setPspConfigurationData] = useState([]);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [selectedProvider, setSelectedProvider] =
        useState<ISelectedProvider>();
    const [selectedCompanyId, setSelectedCompanyId] = useState<string>(
        auth?.isSuperAdmin
            ? auth?.selectedCompany?._id ?? ''
            : auth?.authUser?.company
    );

    useEffect(() => {
        if (auth?.isSuperAdmin) {
            setSelectedCompanyId(auth?.selectedCompany?._id ?? '');
        } else {
            setSelectedCompanyId(auth?.authUser?.company?._id ?? '');
        }
    }, [auth?.selectedCompany?._id]);

    const fetchConfigurationList = async () => {
        setIsDataLoading(true);
        try {
            const { configurations } = await getPaymentProvider(
                selectedCompanyId
            );
            setPspConfigurationData(configurations);
        } catch (err: any) {
            showToast(err.message, 'error');
        } finally {
            setIsDataLoading(false);
        }
    };

    useEffect(() => {
        if (selectedCompanyId) {
            fetchConfigurationList();
            setSelectedProvider(undefined);
        }
    }, [selectedCompanyId]);

    useEffect(() => {
        const filterPaymentProvider = pspConfigurationData.filter(
            (paymentProvider: { type: string }) => {
                return paymentProvider.type === selectedProvider?.value;
            }
        );
        if (!filterPaymentProvider) return;
        setSelectedPspData(filterPaymentProvider);
    }, [selectedProvider, pspConfigurationData]);

    const onSelectPsp = (psp: ISelectedProvider) => {
        const filterPaymentProvider = pspConfigurationData.filter(
            (paymentProvider: { type: string }) => {
                return paymentProvider.type === psp?.value;
            }
        );
        setSelectedPspData(filterPaymentProvider ?? {});
        setSelectedProvider({ name: psp.name, value: psp.value });
    };

    const configurationList = useCallback(() => {
        switch (selectedProvider?.name) {
            case 'Cleo':
                return (
                    <CleoPaymentForm
                        title="Cleo"
                        selectedPspData={selectedPspData}
                        fetchConfigurationList={fetchConfigurationList}
                        selectedCompanyId={selectedCompanyId}
                        isDataLoading={isDataLoading}
                    />
                );
            case 'Trust Payments':
                return (
                    <TrustPaymentForm
                        title="Trust Payments"
                        selectedPspData={selectedPspData}
                        fetchConfigurationList={fetchConfigurationList}
                        selectedCompanyId={selectedCompanyId}
                        isDataLoading={isDataLoading}
                    />
                );
            case 'PayModum':
                return (
                    <PayModumForm
                        title="PayModum"
                        selectedPspData={selectedPspData}
                        fetchConfigurationList={fetchConfigurationList}
                        selectedCompanyId={selectedCompanyId}
                        isDataLoading={isDataLoading}
                    />
                );
            case 'Brite':
                return (
                    <Brite
                        title="Brite"
                        selectedPspData={selectedPspData}
                        fetchConfigurationList={fetchConfigurationList}
                        selectedCompanyId={selectedCompanyId}
                        isDataLoading={isDataLoading}
                    />
                );
            default:
                return <SelectProviderPage />;
        }
    }, [
        selectedPspData,
        selectedProvider?.name,
        selectedCompanyId,
        isDataLoading,
    ]);

    return (
        <div className="flex w-full">
            <div className="px-6 bg-white w-[304px] h-[calc(100vh-100px)] overflow-y-scroll">
                <div className="relative">
                    <div className="fixed bg-white py-5 text-lg font-merri-bold tracking-tighter w-[250px] z-[110]">
                        Payment Providers
                    </div>
                </div>
                <div className="relative pt-20">
                    <SearchMenuList
                        selectedCompanyId={selectedCompanyId}
                        datalist={ProvidersList}
                        onSelectPsp={onSelectPsp}
                        selectedProvider={selectedProvider}
                        className="w-full top-180"
                    />
                </div>
            </div>
            {selectedCompanyId ? (
                <div className="w-[calc(100%-250px)] h-[calc(100vh-100px)] overflow-y-scroll flex justify-center">
                    {configurationList()}
                </div>
            ) : (
                <CompanySelectionMessage />
            )}
        </div>
    );
};

export default Configuration;
