import { useEffect, useState } from 'react';
import { AccountData } from '../components/PaymentMethods/Configuration/configuration.type';
import { getAccounts } from '../services/api/api';
import { useAuthContext } from '../context/Auth/AuthContext';
import { AppActionsEnum } from '../context/Auth/AuthContextValues';

const useGetAccounts = (companyId: string) => {
    const [accounts, setAccounts] = useState<AccountData[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { auth, dispatch } = useAuthContext();

    const fetchAccounts = async () => {
        setLoading(true);
        try {
            const response = await getAccounts({ companyId });
            setAccounts(response?.configurations);
            dispatch({
                type: AppActionsEnum.SET_ACCOUNTS,
                payload: { accounts: response?.configurations },
            });
        } catch (err: any) {
            setError(err?.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (auth?.accounts?.length) {
            setAccounts(auth.accounts);
            setLoading(false);
        } else {
            fetchAccounts();
        }
    }, [auth.accounts]);

    return { accounts, loading, error };
};

export default useGetAccounts;
