import { PaymentProviderConstant } from '../constants/payment.constants';
import { TransactionStatusKeys } from '../constants/common.constants';

export const getTransactionStatus = (transaction: any): string => {
    if (
        transaction.pspType === PaymentProviderConstant.CLEO ||
        transaction.pspType === PaymentProviderConstant.PAYMODUM
    ) {
        return (transaction.status || 'N/A') as string;
    }
    if (transaction.pspType === PaymentProviderConstant.TRUST) {
        switch (transaction?.transactionDetails?.settlestatus) {
            case '0':
                return TransactionStatusKeys.PENDING;
            case '1':
                return TransactionStatusKeys.MANUAL;
            case '2':
                return TransactionStatusKeys.SUSPENDED;
            case '3':
                return TransactionStatusKeys.CANCELLED;
            case '10':
                return TransactionStatusKeys.SETTLING;
            case '100':
                return TransactionStatusKeys.SUCCESS;
            default:
                return TransactionStatusKeys.FAILED;
        }
    }
    if (transaction.pspType === PaymentProviderConstant.BRITE) {
        switch (transaction.transactionState) {
            case 0:
            case 1:
            case 2:
                return TransactionStatusKeys.PENDING;
            case 10:
                return TransactionStatusKeys.CANCELLED;
            case 11:
                return TransactionStatusKeys.FAILED;
            case 12:
                return TransactionStatusKeys.SUCCESS;
            default:
                return TransactionStatusKeys.FAILED;
        }
    }
    return '';
};

export const getTrustTransactionAuthStatus = (errorcode: string) => {
    if (errorcode === '0') {
        return TransactionStatusKeys.SUCCESS;
    }
    return TransactionStatusKeys.ERROR;
};

export const getTrustTransactionStatus = (settleStatus: string) => {
    const trustTransactionStatusMap: Record<string, string> = {
        '0': TransactionStatusKeys.PENDING,
        '1': TransactionStatusKeys.MANUAL,
        '2': TransactionStatusKeys.SUSPENDED,
        '3': TransactionStatusKeys.CANCELLED,
        '10': TransactionStatusKeys.SETTLING,
        '100': TransactionStatusKeys.SUCCESS,
    };
    return (
        trustTransactionStatusMap[settleStatus] || TransactionStatusKeys.FAILED
    );
};
