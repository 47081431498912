import React, { useEffect, useState } from 'react';
import { Loader, RFTextField } from 'components/Common';
import { updatePaymentProvider } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { PaymentProviderConstant } from 'data/constants/payment.constants';
import {
    AccountData,
    ConfigurationType,
    IPspConfigurationProps,
} from '../configuration.type';
import { paymodumFormSchemaValidation } from '../configurationUtills';
import AccountManagerHeader from '../common/AccountManagerHeader/AccountManagerHeader';
import AccountManagerFooter from '../common/AccountManagerFooter/AccountManagerFooter';

const PayModumForm = ({
    title,
    selectedPspData,
    fetchConfigurationList,
    selectedCompanyId,
    isDataLoading,
}: IPspConfigurationProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [accounts, setAccounts] = useState<AccountData[]>(selectedPspData);
    const [activeAccount, setActiveAccount] = useState<AccountData>(
        selectedPspData?.[0]
    );

    const { control, handleSubmit, reset } = useForm<ConfigurationType>({
        resolver: zodResolver(paymodumFormSchemaValidation),
        mode: 'onChange',
        defaultValues: {},
    });

    useEffect(() => {
        setAccounts(selectedPspData);
    }, [selectedPspData]);

    const initialFormValues = {
        accountName: 'Account Name',
        username: '',
        password: '',
        comment: '',
        _id: new Date().getTime().toString(),
        isDefaultAccount: false,
        isTestAccount: false,
        isDeleted: false,
        updatedAt: '',
        isDeActivatedAccount: false,
    };

    useEffect(() => {
        if (activeAccount) {
            reset(activeAccount);
        }
    }, [activeAccount, reset]);

    useEffect(() => {
        if (selectedPspData && selectedPspData.length > 0) {
            setAccounts(selectedPspData);
            if (!activeAccount) setActiveAccount(selectedPspData[0]);
        }
    }, [selectedPspData]);

    const onSubmit = handleSubmit(async (formData) => {
        setLoading(true);
        const payload = {
            type: PaymentProviderConstant.PAYMODUM,
            companyId: selectedCompanyId,
            ...formData,
        };
        try {
            await updatePaymentProvider(payload, activeAccount?._id);
            showToast('successfully updated', 'success');
            fetchConfigurationList();
        } catch (err: any) {
            showToast(err.message, 'error');
        } finally {
            setLoading(false);
        }
    });

    if (isDataLoading)
        return (
            <div className="my-5">
                <Loader />
            </div>
        );

    return (
        <form
            className="w-full px-8 flex flex-col py-2 box-border"
            onSubmit={onSubmit}
        >
            <div className="text-2xl font-merri-bold tracking-tighter">
                {title}
            </div>

            <AccountManagerHeader
                accounts={accounts}
                activeAccount={activeAccount}
                setActiveAccount={setActiveAccount}
                initialFormValues={initialFormValues}
                fetchConfigurationList={fetchConfigurationList}
                selectedPspDataLength={selectedPspData.length}
                selectedCompanyId={selectedCompanyId}
                pspType={PaymentProviderConstant.PAYMODUM}
            />

            {selectedPspData.length > 0 ? (
                <div className="flex flex-col gap-6">
                    <div>
                        <RFTextField
                            control={control}
                            name="accountName"
                            label={
                                <>
                                    <span className="font-poppins-medium">
                                        Name of Account
                                    </span>
                                    <span className="ps-2 text-xs">
                                        (max 32 characters)
                                    </span>
                                </>
                            }
                            type="text"
                            fullWidth
                            asterisk
                        />
                        <RFTextField
                            control={control}
                            name="username"
                            fullWidth
                            label="User Name"
                            type="password"
                            asterisk
                            iconPosition="right"
                        />
                        <RFTextField
                            control={control}
                            name="password"
                            label="Password"
                            fullWidth
                            type="password"
                            asterisk
                            iconPosition="right"
                        />
                        <RFTextField
                            control={control}
                            name="comment"
                            label="Comment/Text"
                            type="text"
                            fullWidth
                            iconPosition="right"
                        />
                        <AccountManagerFooter
                            loading={loading}
                            disabled={accounts.length === 1}
                            activeAccount={activeAccount}
                            setActiveAccount={setActiveAccount}
                            accounts={accounts}
                            fetchConfigurationList={fetchConfigurationList}
                        />
                    </div>
                </div>
            ) : (
                <div className="text-4xl font-poppins-bold tracking-tight text-gray-500 text-center">
                    Please add account for paymodaum
                </div>
            )}
        </form>
    );
};
export default PayModumForm;
