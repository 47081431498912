import { ChangeEvent, useState } from 'react';
import LoadingSpinner from 'components/Common/Loader/Loader';
import { Pagination, Table } from 'components/Common';
import TransactionDetailModal from 'components/Modal/TransactionDetailsModal';
import { ITransactions } from 'data/types/response';
import { ITransactionTable } from '../Transaction.type';
import { transactionTableColumn } from './TransactionTableUtill';

export const TransactionTable = ({
    loading,
    currentPage,
    transactionData,
    setCurrentPage,
    setFilterData,
    filterData,
    tableFilterColumn,
    totalPage,
    checkedTransactions,
    setCheckedTransactions,
    setSelectedRowStatus,
}: ITransactionTable) => {
    const [pageLimit, setPageLimit] = useState<number>(10);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [transactionDetails, setTransactionDetails] =
        useState<ITransactions | null>(null);

    const handleOnSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        if (transactionData?.length < parseInt(e.target.value)) {
            setCurrentPage(1);
        }
        setPageLimit(parseInt(e.target.value));
        setFilterData({
            ...filterData,
            limit: parseInt(e.target.value),
            page: 1,
        });
    };

    const filteredArray = transactionTableColumn({
        checkedTransactions,
        setCheckedTransactions,
        setSelectedRowStatus,
        setIsOpen,
    }).filter((item) => {
        const correspondingItem = tableFilterColumn.find(
            (secondItem) => secondItem.key === item.key
        );
        return correspondingItem?.isChecked;
    });

    return (
        <div>
            {isOpen && (
                <TransactionDetailModal
                    transaction={transactionDetails}
                    isTransactionModal
                    setIsOpen={setIsOpen}
                />
            )}

            <div className="max-h-[calc(100vh-294px)] !overflow-y-scroll !overflow-x-scroll dataTable rounded-xl mt-5">
                <div className="flex h-full w-full items-center">
                    {loading ? (
                        <div className="flex h-full w-full items-center justify-center">
                            <LoadingSpinner />
                        </div>
                    ) : (
                        <Table
                            isTransactionDetailsTable
                            handleRowClick={(details) => {
                                // setUserInfo(details);
                                setTransactionDetails(details);
                                setIsOpen(true);
                            }}
                            data={transactionData}
                            columns={filteredArray}
                            className="w-full"
                            colSpan={filteredArray?.length}
                            setFilterData={setFilterData}
                            filterData={filterData}
                        />
                    )}
                </div>
            </div>
            <div className="mt-2">
                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={totalPage || 0}
                    pageSize={pageLimit}
                    onPageChange={(page: number) => {
                        setFilterData({ ...filterData, page });
                        setCurrentPage(page);
                    }}
                    siblingCount={1}
                    handleOnSelect={handleOnSelect}
                    text="Show Transactions"
                />
            </div>
        </div>
    );
};
