import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Drawer } from 'components/Common';
import SvgIcon from 'components/Common/Icon/SvgIcon';
import { useAuthContext } from 'context/Auth/AuthContext';
import { updateCompany } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { IUpdateCompanyPayload } from 'data/types/request';
import { ICompaniesDrawerProps, ICompanyProps } from '../Companies.type';
import { formSchemaValidation } from '../Companies.schema';
import { CompaniesForm } from './CompaniesForm/CompaniesForm';
import { getAllCompanyBrands } from '../../companyBrandService';

const EditCompaniesDrawer = ({
    isOpen,
    openDrawer,
    isEditCompany,
    company,
    getAllCompanies,
    setIsOpen,
    setCompany,
}: ICompaniesDrawerProps) => {
    const { dispatch } = useAuthContext();

    const [companyLoading, setCompanyLoading] = useState<boolean>(false);

    const { control, handleSubmit, reset } = useForm<ICompanyProps>({
        resolver: zodResolver(formSchemaValidation),
    });

    useEffect(() => {
        if (!company) return;
        const asyncDefaultValues: ICompanyProps = {
            companyName: company?.name,
            billingCurrency: company?.billingCurrency,
        };
        reset(asyncDefaultValues);
    }, [reset, company]);

    const onSubmit = handleSubmit((formData: ICompanyProps) => {
        if (!formData) return;
        const payload: IUpdateCompanyPayload = {
            name: formData?.companyName,
            billingCurrency: formData?.billingCurrency,
        };
        if (company?.name === formData?.companyName) {
            delete payload.name;
        }
        try {
            if (isEditCompany && company) {
                setCompanyLoading(true);
                updateCompany(company._id, payload)
                    .then(() => {
                        getAllCompanyBrands(dispatch, setCompanyLoading);
                        getAllCompanies('', '');
                        setCompany(null);
                        setIsOpen(false);
                        setCompanyLoading(false);
                        showToast('Successfully Updated');
                    })
                    .catch((err) => {
                        setCompanyLoading(false);
                        showToast(
                            err?.errors?.[0]?.message || 'something went wrong',
                            'error'
                        );
                    });
            }
        } catch (error: any) {
            setCompanyLoading(false);
            showToast(error?.response?.data || 'something went wrong', 'error');
        }
    });

    return (
        <Drawer
            drawerSize={400}
            isOpen={isOpen}
            toggleDrawer={() => openDrawer()}
            drawerDirection="right"
            className={classNames(
                'bg-pink-100  max-h-[100vh]',
                companyLoading ? '' : 'overflow-y-scroll'
            )}
        >
            <div className="p-5">
                <div className="flex items-center justify-between">
                    <div className="my-0 text-xl font-black text-green-100 font-merri-bold tracking-tighter">
                        Update Company
                    </div>
                    <SvgIcon
                        className="cursor-pointer"
                        icon="CLOSE_BUTTON"
                        onClick={() => openDrawer()}
                    />
                </div>
                <CompaniesForm
                    company={company}
                    companyLoading={companyLoading}
                    handleSubmit={onSubmit}
                    control={control}
                    isEditCompany={isEditCompany}
                />
            </div>
        </Drawer>
    );
};

export default EditCompaniesDrawer;
