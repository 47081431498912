import React, { useState } from 'react';
import { Modal, PrimaryButton, SvgIcon } from 'components/Common';
import './PaymentStatusModal.css';

const PaymentStatusModal = ({
    setPaymentStatusModal,
    getInvoiceData,
}: {
    setPaymentStatusModal: React.Dispatch<React.SetStateAction<boolean>>;
    getInvoiceData: () => void;
}) => {
    const [isLoading] = useState(false);
    return (
        <Modal
            classname="w-[592px] !p-0 box-border !rounded-3xl"
            setIsOpen={setPaymentStatusModal}
            isBillingModal
            title=""
        >
            {isLoading ? (
                <>
                    <div className="p-8 pt-6">
                        <div className="payment-loader" />
                        <div className="text-center text-base text-[#131119] mt-[34px] leading-5">
                            Please wait while we process your payment. This may
                            take a few moments. If it takes longer than
                            expected, please try again.
                        </div>
                    </div>
                    <div className="px-8">
                        <PrimaryButton
                            color="#2E672F"
                            name="Go back and try again!"
                            className="w-full !h-12 !mb-10 !mt-8 !text-base !rounded-xl"
                            variant="filled"
                            type="submit"
                        />
                    </div>
                </>
            ) : (
                <div className="text-center p-8 pt-0">
                    <div className="pt-[3px] pb-[22px]">
                        <SvgIcon icon="BIG_SUCCESS_CHECK_ICON" />
                    </div>
                    <div>
                        <div className="text-[#2E672F] h-[22px] leading-8 text-2xl font-merri-bold">
                            Payment Processed Successfully!
                        </div>
                        <div className="text-center text-base text-[#131119] mt-6 mb-11 leading-5">
                            Payment Method Added. You can now use this method
                            for seamless transactions.
                        </div>
                    </div>
                    <PrimaryButton
                        color="#2E672F"
                        name="Go to payment and billing"
                        className="w-full !h-12 !mb-2 !text-base !rounded-xl"
                        variant="filled"
                        type="submit"
                        onClick={() => {
                            setPaymentStatusModal(false);
                            getInvoiceData();
                        }}
                    />
                </div>
            )}
        </Modal>
    );
};
export default PaymentStatusModal;
