import React, { ChangeEvent, useEffect, useState } from 'react';
import { showToast } from 'data/utils/toast';
import { getCurrentPlan } from 'services/api/api';
import { getDateFormat } from 'data/utils/common';
import { useAuthContext } from 'context/Auth/AuthContext';
import { AppActionsEnum } from 'context/Auth/AuthContextValues';
import {
    ICurrentPlanData,
    IInvoiceData,
    invoiceFilterOptions,
    IPricingAndBillingsProps,
} from '../Billing.types';
import { InvoiceTable } from './InvoiceTable/InvoiceTable';
import { getCurrencySign } from '../Billing.utils';
import { Loader, PrimaryButton, Select, SvgIcon } from '../../Common';

const PricingAndBillings = ({
    invoiceData,
    getInvoiceData,
    setUpdatePaymentPlanModal,
    setIsOpen,
}: IPricingAndBillingsProps) => {
    const [tableCollapsed, setTableCollapsed] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState<string>('ALL');
    const [filteredInvoices, setFilteredInvoices] =
        useState<IInvoiceData[]>(invoiceData);
    const { auth, dispatch } = useAuthContext();
    const [currentPlan, setCurrentPlan] = useState<ICurrentPlanData>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setFilteredInvoices(invoiceData);
    }, [invoiceData]);

    const handleFilterSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        const filter = e.target.value;
        setSelectedFilter(filter);
        const filteredData = invoiceData.filter(
            (ele) => ele?.status === filter
        );
        setFilteredInvoices(filter === 'ALL' ? invoiceData : filteredData);
    };

    const fetchCurrentPlan = async () => {
        try {
            setLoading(true);
            const data = await getCurrentPlan();
            setCurrentPlan(data?.billingsInvoices?.[0]);
            dispatch({
                type: AppActionsEnum.SET_CURRENT_PLAN,
                payload: { currentPlan: data?.billingsInvoices?.[0] },
            });
        } catch (error: any) {
            showToast(error?.message, 'error');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!auth?.currentPlan) {
            fetchCurrentPlan();
        } else {
            setCurrentPlan(auth?.currentPlan);
        }
    }, [auth?.currentPlan]);

    return loading ? (
        <Loader />
    ) : (
        <div>
            <div className="font-merri-bold text-2xl pb-4">
                Pricing and Billing
            </div>
            {invoiceData.length > 0 && !auth?.isSuperAdmin && (
                <div className="flex justify-between items-center mt-6 gap-x-5 bg-[#449745] text-white rounded-[20px] px-8 py-4">
                    <div className="space-y-0.5">
                        <div className="font-poppins-bold text-xl leading-6">
                            Current plan
                        </div>
                        <div className="text-base leading-5">
                            You are on the {currentPlan?.planName} plan
                        </div>
                        <div className="text-[#9ED79F] text-xs leading-4">
                            Next Payment Due:{' '}
                            {getDateFormat(currentPlan?.endDate ?? '')}
                        </div>
                    </div>
                    <div className="flex items-center justify-between gap-x-3">
                        <div className="!px-4 !h-14  box-border !text-base !rounded-xl bg-[#539f54] flex flex-col justify-center">
                            <div className="flex items-center gap-1 pb-0.5 justify-between w-full">
                                <div className="text-xs">
                                    {currentPlan?.planName}
                                </div>
                                <SvgIcon
                                    icon="SMALL_CHECK_ICON"
                                    className="stroke-white h-[16px]"
                                />
                            </div>
                            <div className="flex gap-3 h-5 items-end">
                                <div className="text-lg flex gap-1">
                                    <div className="text-lg font-poppins-bold leading-[22px]">
                                        {currentPlan?.amount}
                                    </div>
                                    <div className="text-lg font-poppins-bold leading-[22px]">
                                        {getCurrencySign(
                                            currentPlan?.currency ?? ''
                                        )}
                                    </div>
                                </div>
                                <div className="flex gap-1 h-5">
                                    <div className="text-[8px]">/per</div>
                                    <div className="capitalize text-[8px]">
                                        {currentPlan?.unit?.toLowerCase()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <PrimaryButton
                            color="#8FB131"
                            name="Change Plan"
                            className="!px-4 !h-14 !text-base !rounded-xl"
                            variant="filled"
                            type="button"
                            onClick={() => setUpdatePaymentPlanModal(true)}
                        />
                        <PrimaryButton
                            color="#8FB131"
                            name="Manage Cards"
                            className="!px-4 !h-14 !text-base !rounded-xl"
                            variant="filled"
                            type="button"
                            onClick={() => setIsOpen(true)}
                        />
                    </div>
                </div>
            )}
            {!auth?.isSuperAdmin &&
                (invoiceData.length > 0 ? (
                    <>
                        <div className="flex items-center justify-between gap-x-6 my-6">
                            <button
                                type="button"
                                aria-label="colllapse"
                                onClick={() =>
                                    setTableCollapsed(!tableCollapsed)
                                }
                                className="flex items-center px-0 gap-x-4 bg-transparent border-none cursor-pointer"
                            >
                                <SvgIcon
                                    icon={
                                        tableCollapsed
                                            ? 'ARROW_UP'
                                            : 'ARROW_DOWN'
                                    }
                                    className="stroke-black pb-0.5"
                                />
                                <div className="font-poppins-bold text-base leading-5">
                                    Payment History
                                </div>
                            </button>
                            <Select
                                isGroup
                                isMarginBottom={false}
                                value={selectedFilter}
                                options={[
                                    { _id: 'ALL', name: 'Show All' },
                                    ...invoiceFilterOptions,
                                ]}
                                handleOnSelect={handleFilterSelect}
                                className="min-w-36 font-poppins-medium !pr-2 !border-2 !border-[#818181] !rounded-xl px-4 !text-xs !h-11"
                            />
                        </div>
                        {!tableCollapsed && (
                            <InvoiceTable
                                pricingData={filteredInvoices}
                                getInvoiceData={getInvoiceData}
                            />
                        )}
                    </>
                ) : (
                    <div className="text-base pt-5">
                        You don't have any plan selected, please select a plan
                        from the price plan below.
                    </div>
                ))}
        </div>
    );
};
export default PricingAndBillings;
