import {
    BILLING_INVOICE_STATUS,
    BILLING_INVOICE_STATUS_LABEL,
} from './Billing.types';

export const getCurrencySign = (currency: string): string => {
    const currencySymbols: Record<string, string> = {
        CLP: 'CLP',
        EURO: '€',
        EUR: '€',
        USD: '$',
        GBP: '£',
    };
    return currencySymbols[currency] || '€';
};

export const getStatus = (status: BILLING_INVOICE_STATUS): string => {
    const statusLabels = {
        [BILLING_INVOICE_STATUS.PAID]: BILLING_INVOICE_STATUS_LABEL.PAID,
        [BILLING_INVOICE_STATUS.OUTSTANDING]:
            BILLING_INVOICE_STATUS_LABEL.OUTSTANDING,
    };
    return statusLabels[status] || status;
};

export const featureList: string[] = new Array(6).fill(
    'Manage up to 1,000 payment providers'
);
