import { z, ZodType } from 'zod';
import { ICompanyProps } from './Companies.type';

export const formSchemaValidation: ZodType<ICompanyProps> = z
    .object({
        companyName: z
            .string({ required_error: 'Company name is required' })
            .trim()
            .min(1, 'Company name  is required'),
        billingCurrency: z
            .string({ required_error: 'Billing currency is required' })
            .trim()
            .min(1, { message: 'Billing currency is required' }),
    })
    .required();
