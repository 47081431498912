import { useState, useEffect } from 'react';
import { useAuthContext } from 'context/Auth/AuthContext';
import { AppActionsEnum } from 'context/Auth/AuthContextValues';

function useFetchCountries() {
    const [countries, setCountries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { auth, dispatch } = useAuthContext();

    const fetchCountries = async () => {
        try {
            const response = await fetch(`https://restcountries.com/v3.1/all`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setCountries(data);
            dispatch({
                type: AppActionsEnum.SET_COUNTRIES,
                payload: { countries: data },
            });
        } catch (err: any) {
            setError(err?.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (auth.countries.length) {
            setCountries(auth.countries);
            setLoading(false);
        } else {
            fetchCountries();
        }
    }, [auth.countries]);

    return { countries, loading, error };
}

export default useFetchCountries;
