import { ReactFlowProvider } from 'reactflow';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Companies from 'components/Admin/Companies/Companies';
import Groups from 'components/Admin/Groups/Groups';
import User from 'components/Admin/User/User';
import PaymentApprovals from 'components/Operations/Approvals/Approvals';
import PaymentTransactions from 'components/Operations/Transactions/Transaction';
import ApiKeys from 'components/Admin/ApiKeys/ApiKeys';
import Simulator from 'components/PaymentMethods/Simulator';
import CreateRuleBuilder from 'components/RuleEngine/RuleBuilder/CreateRuleBuilder';
import Brands from 'components/Admin/Brands/Brands';
import Configuration from 'components/PaymentMethods/Configuration';
import ForgotPasswordPage from 'pages/auth/ForgotPassword';
import Login from 'pages/auth/Login';
import NewAdminPassword from 'pages/auth/NewAdminPassword';
import ResetPasswordPage from 'pages/auth/ResetPassword';
import Orchestration from 'pages/Orchestration/Orchestration';
import ValidateResetToken from 'pages/auth/ValidateResetToken';
import ValidateSignupToken from 'pages/auth/ValidateSignupToken';
import CleoCallback from 'pages/CleoCallback/CleoCallback';
import AcceptableUsagePolicy from 'pages/AcceptableUsagePolicy/AcceptableUsagePolicy';
import { useSideBarItemAsPerRole } from 'hooks/commonHook';
import { useAuthContext } from 'context/Auth/AuthContext';
import { PageIdentifiers } from 'data/constants/common.constants';
import Authorization from './Authorization';
import OnBoardCashier from '../pages/OnBoardCashier/OnBoardCashier';
import { Layout } from '../components/Common';
import AuditPage from '../components/Audit';
import Billing from '../pages/Billing/Billing';
import InvoiceSummary from '../components/Billing/InvoiceSummary/InvoiceSummary';

type SubMenuItem = {
    key: keyof typeof pageComponentMap;
    path?: string;
};

type MenuItem = {
    key: string;
    path?: string;
    subMenu?: SubMenuItem[];
};

const pageComponentMap = {
    [PageIdentifiers.USER_MANAGEMENT]: User,
    [PageIdentifiers.COMPANIES]: Companies,
    [PageIdentifiers.ACTIVITY_LOG]: AuditPage,
    [PageIdentifiers.GROUPS]: Groups,
    [PageIdentifiers.SUB_BRANDS]: Brands,
    [PageIdentifiers.API_KEYS]: ApiKeys,
    [PageIdentifiers.APPROVALS]: PaymentApprovals,
    [PageIdentifiers.TRANSACTIONS]: PaymentTransactions,
    [PageIdentifiers.CONFIGURATION]: Configuration,
    [PageIdentifiers.SIMULATOR]: Simulator,
};

export const RouteList = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { auth } = useAuthContext();
    const [isRegisterUser, setIsRegisterUser] = useState(auth.isRegisterUser);

    const { getSideBarItemAsPerRole } = useSideBarItemAsPerRole({ auth }) as {
        getSideBarItemAsPerRole: MenuItem[];
    };

    const getIndexComponent = (menuKey: keyof typeof PageIdentifiers) => {
        if (!getSideBarItemAsPerRole.length) return null;
        const matchedMenuItem = getSideBarItemAsPerRole.find(
            (menu) => menu?.key === menuKey
        );
        const firstSubMenuKey = matchedMenuItem?.subMenu?.[0]?.key;

        const mappedComponent =
            pageComponentMap[firstSubMenuKey as keyof typeof pageComponentMap];
        if (!mappedComponent) {
            return <div>Page not found</div>; // Fallback UI
        }
        return React.createElement(mappedComponent as React.ElementType);
    };

    useEffect(() => {
        if (!getSideBarItemAsPerRole.length) return;

        const allPaths: string[] = getSideBarItemAsPerRole.reduce<string[]>(
            (acc, menu) => {
                if (menu.path) acc.push(menu.path);
                if (menu.subMenu) {
                    menu.subMenu.forEach((subMenu: any) => {
                        if (subMenu.path) acc.push(subMenu.path);
                    });
                }
                return acc;
            },
            []
        );
        const token = localStorage.getItem('token');

        const extendedPaths = allPaths.includes('/workflows/orchestration')
            ? [
                  ...allPaths,
                  '/workflows/orchestration/rule-builder',
                  '/onboard-cashier-details',
                  '/acceptable-usage-policy',
                  '/billing',
                  '/invoice',
              ]
            : [
                  ...allPaths,
                  '/onboard-cashier-details',
                  '/acceptable-usage-policy',
                  '/billing',
                  '/invoice',
              ];

        // if (auth?.isSuperAdmin) {
        //     extendedPaths = extendedPaths.filter((path) => path !== '/billing');
        // }

        if (auth?.isRegisterUser && isRegisterUser) {
            navigate('/billing');
            setIsRegisterUser(false);
            return;
        }
        if (!extendedPaths.includes(location.pathname)) {
            if (token) {
                navigate(extendedPaths[0] ?? '/operations');
            } else {
                navigate('/');
            }
        }
    }, [location.pathname, getSideBarItemAsPerRole]);

    return (
        <Routes>
            <Route element={<Authorization />}>
                <Route path="cleo/auth" element={<CleoCallback />} />

                <Route path="acceptable-usage-policy" element={<Layout />}>
                    <Route index element={<AcceptableUsagePolicy />} />
                </Route>

                <Route path="billing" element={<Layout />}>
                    <Route index element={<Billing />} />
                </Route>

                <Route path="invoice" element={<InvoiceSummary />} />

                <Route path="operations" element={<Layout />}>
                    <Route
                        index
                        element={getIndexComponent(PageIdentifiers.OPERATIONS)}
                    />
                    <Route
                        path="transactions"
                        element={<PaymentTransactions />}
                    />
                    <Route path="approvals" element={<PaymentApprovals />} />
                </Route>

                <Route path="workflows" element={<Layout />}>
                    <Route path="orchestration" element={<Orchestration />} />
                </Route>

                <Route
                    path="workflows/orchestration/rule-builder/:id?"
                    element={
                        <ReactFlowProvider>
                            <CreateRuleBuilder />
                        </ReactFlowProvider>
                    }
                />

                <Route path="payment-methods" element={<Layout />}>
                    <Route
                        index
                        element={getIndexComponent(
                            PageIdentifiers.PAYMENT_METHODS
                        )}
                    />
                    <Route path="configuration" element={<Configuration />} />
                    <Route path="simulator" element={<Simulator />} />
                </Route>

                <Route path="admin" element={<Layout />}>
                    <Route
                        index
                        element={getIndexComponent(PageIdentifiers.ADMIN)}
                    />
                    <Route path="existing-users" element={<User />} />
                    <Route path="companies" element={<Companies />} />
                    <Route path="activity-log" element={<AuditPage />} />
                    <Route path="groups" element={<Groups />} />
                    <Route path="brands" element={<Brands />} />
                    <Route path="api-keys" element={<ApiKeys />} />
                </Route>

                <Route
                    path="onboard-cashier-details"
                    element={<OnBoardCashier />}
                />
            </Route>

            <Route path="login" element={<Login />} />
            <Route path="/" element={<Login />} />
            <Route
                path="validate-signup-token"
                element={<ValidateSignupToken />}
            />
            <Route
                path="validate-reset-token"
                element={<ValidateResetToken />}
            />
            <Route path="forgot-password" element={<ForgotPasswordPage />} />
            <Route path="reset-password" element={<ResetPasswordPage />} />
            <Route path="create-new-password" element={<NewAdminPassword />} />
        </Routes>
    );
};
