import React, { useEffect, useState } from 'react';
import Plans from 'components/Billing/Plans/Plans';
import PricingAndBillings from 'components/Billing/PricingAndBillings/PricingAndBillings';
import { getBillingInformation, getPlans } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { IPlanData, IInvoiceData } from 'components/Billing/Billing.types';
import { Loader } from 'components/Common';
import PaymentPlanModal from 'components/Billing/Plans/modals/PaymentPlanModal/PaymentPlanModal';
import PaymentMethodsDrawer from 'components/Billing/PricingAndBillings/PaymentMethodsDrawer/PaymentMethodsDrawer';
import { useAuthContext } from 'context/Auth/AuthContext';

const Billing = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [invoiceData, setInvoiceData] = useState<IInvoiceData[]>([]);
    const [plans, setPlans] = useState<IPlanData[]>([]);
    const [updatePaymentPlanModal, setUpdatePaymentPlanModal] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const { auth } = useAuthContext();

    const fetchInvoiceData = async () => {
        setIsLoading(true);
        try {
            const response = await getBillingInformation();
            setInvoiceData(response?.billingsInvoices || []);
        } catch (err: any) {
            showToast(err?.errors?.[0]?.msg ?? err.message, 'error');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchPlansData = async () => {
        setIsLoading(true);
        const companyId = auth?.selectedCompany?._id;
        try {
            const response = await getPlans(companyId ?? '');
            const sortedData = (response?.plans || []).sort(
                (a: { price: number }, b: { price: number }) =>
                    a.price - b.price
            );
            setPlans(sortedData);
        } catch (err: any) {
            showToast(err?.errors?.[0]?.msg ?? err.message, 'error');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchInvoiceData();
        fetchPlansData();
    }, [auth?.selectedCompany?._id]);

    if (isLoading) {
        return (
            <div className="p-5">
                <Loader />
            </div>
        );
    }

    return (
        <>
            <div className="p-8 space-y-9">
                <PricingAndBillings
                    invoiceData={invoiceData}
                    getInvoiceData={fetchInvoiceData}
                    setUpdatePaymentPlanModal={setUpdatePaymentPlanModal}
                    setIsOpen={setIsOpen}
                />
                <Plans
                    plans={plans}
                    invoiceData={invoiceData}
                    getInvoiceData={fetchInvoiceData}
                    fetchPlansData={fetchPlansData}
                />
            </div>
            {updatePaymentPlanModal && (
                <PaymentPlanModal
                    plans={plans}
                    getInvoiceData={fetchInvoiceData}
                    setUpdatePaymentPlanModal={setUpdatePaymentPlanModal}
                    fetchPlansData={fetchPlansData}
                />
            )}
            <PaymentMethodsDrawer isOpen={isOpen} setIsOpen={setIsOpen} />
        </>
    );
};

export default Billing;
