import { z, ZodType } from 'zod';
import { IBrandsPermissions } from 'data/common';
import { IUserType } from './User.type';

const brandPermissionSchema: ZodType<IBrandsPermissions> = z.object({
    brandId: z.string({ required_error: 'Brand ID is required' }),
    isRead: z.boolean({ required_error: 'isRead is required' }),
    isWrite: z.boolean({ required_error: 'isWrite is required' }),
    _id: z.string().optional(),
});

const commonSchema = z
    .object({
        email: z
            .string({ required_error: 'Email is required' })
            .email('Invalid email format'),
        firstName: z
            .string({ required_error: 'First name is required' })
            .trim()
            .min(1, 'First name is required'),
        lastName: z
            .string({ required_error: 'Last name is required' })
            .trim()
            .min(1, 'Last name is required'),
        job: z
            .string({
                required_error: 'Job is required',
            })
            .trim()
            .min(1, 'Job is required'),
        phone: z
            .string({ required_error: 'Phone number is required' })
            .trim()
            .min(1, 'Phone number is required'),
        group: z.string({ required_error: 'Select your group is required' }),
        brandsPermissions: z.array(brandPermissionSchema).optional().nullable(),
        pageAreasPermissions: z
            .array(
                z.object({
                    pageAccessId: z.string({
                        required_error: 'Page access ID is required',
                    }),
                    isRead: z.boolean({
                        required_error: 'Read permission is required',
                    }),
                    isWrite: z.boolean({
                        required_error: 'Write permission is required',
                    }),
                })
            )
            .optional()
            .nullable(),
        role: z.string().optional().nullable(),
        company: z.string().optional().nullable(),
    })
    .required();

export const formSchemaValidation = (
    isSuperAdmin: boolean,
    isAdmin: boolean
): ZodType<IUserType> => {
    if (!isSuperAdmin && !isAdmin) {
        return commonSchema.required();
    }
    if (isAdmin) {
        return commonSchema
            .extend({
                role: z
                    .string({ required_error: 'Select your role' })
                    .refine((val) => val !== '', {
                        message: 'Select your role',
                        path: ['role'],
                    }),
            })
            .required();
    }

    return commonSchema
        .extend({
            role: z
                .string({ required_error: 'Select your role' })
                .refine((val) => val !== '', {
                    message: 'Select your role',
                    path: ['role'],
                }),
            company: z
                .string({ required_error: 'Select your Company' })
                .refine((val) => val !== '', {
                    message: 'Select your Company is required',
                    path: ['company'],
                }),
        })
        .required();
};
