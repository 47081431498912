import React, { useState } from 'react';
import { deletePlan, makePayment } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import ConfirmationModal from 'components/Modal/ConfirmationModal/ConfirmationModal';
import { PrimaryButton, SvgIcon } from 'components/Common';
import { useAuthContext } from 'context/Auth/AuthContext';
import FeatureLine from '../FeatureLine/FeatureLine';
import { BILLING_INVOICE_STATUS, IPlanDataProps } from '../../../Billing.types';
import { getCurrencySign } from '../../../Billing.utils';

const PlanCard = ({
    price,
    name,
    currency,
    unit,
    selectedPlan,
    setSelectedPlan,
    _id,
    getInvoiceData,
    type,
    fetchPlansData,
    features,
    setActiveEditPlanId,
    isChangePlanModal = false,
    isEditable = false,
}: IPlanDataProps) => {
    const isSelected = selectedPlan === _id;
    const [isConfirmationModal, setIsConfirmationModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const { auth } = useAuthContext();

    const isCurrentPlan =
        auth?.currentPlan?.planId === _id && isChangePlanModal;

    const buyPlan = async () => {
        const payload = {
            planId: _id,
            amount: price,
            currency,
            status: BILLING_INVOICE_STATUS.OUTSTANDING,
            paymentDetails: {},
        };
        setIsLoading(true);
        try {
            const response = await makePayment(payload);
            showToast(response?.message, 'success');
            setSelectedPlan(_id);
            getInvoiceData();
        } catch (err: any) {
            showToast(
                err?.errors?.[0]?.message || 'something went wrong',
                'error'
            );
        } finally {
            setIsLoading(false);
            setIsConfirmationModal(false);
        }
    };

    const handleDeletePlan = async () => {
        try {
            setIsLoading(true);
            const response = await deletePlan(_id);
            showToast(response?.message, 'success');
            fetchPlansData();
        } catch (err: any) {
            showToast(
                err?.errors?.[0]?.message || 'something went wrong',
                'error'
            );
        } finally {
            setIsLoading(false);
            setIsDeleteModal(false);
        }
    };

    return (
        <div
            className={`transition-all duration-200 ${
                isSelected
                    ? 'bg-[#449745] text-white'
                    : 'bg-[#F8FBEA] text-black'
            }  p-1 rounded-[10px] max-h-[384px] box-border w-[360px] shadow-md `}
        >
            {isConfirmationModal && (
                <ConfirmationModal
                    onConfirmation={buyPlan}
                    message={`Are you sure you want the ${name} plan for ${price}${getCurrencySign(
                        currency
                    )}?`}
                    isLoading={isLoading}
                    setIsModalOpen={setIsConfirmationModal}
                    onCancellation={() => {
                        setIsConfirmationModal(false);
                    }}
                />
            )}

            {isDeleteModal && (
                <ConfirmationModal
                    onConfirmation={handleDeletePlan}
                    message={`Are you sure you want to remove ${name} plan ?`}
                    isLoading={isLoading}
                    setIsModalOpen={setIsDeleteModal}
                    onCancellation={() => {
                        setIsDeleteModal(false);
                    }}
                />
            )}

            <div
                className={`${
                    isSelected || isCurrentPlan
                        ? 'bg-[#449745] text-white'
                        : 'bg-white text-black'
                } rounded-[10px] ${
                    auth?.isSuperAdmin ? 'h-[375px]' : 'max-h-[384px]'
                }  box-border relative px-[30px] pt-[26px] pb-8 space-y-4 transition-all duration-200`}
            >
                <div className="flex items-center justify-between h-6">
                    <div className="font-poppins-bold text-base leading-[20px]">
                        {name}
                    </div>
                    <div className="flex items-center gap-x-2">
                        {type === 'CUSTOM' && !isSelected && isEditable && (
                            <div className="absolute right-4 top-4 flex items-center gap-2">
                                <button
                                    type="button"
                                    aria-label="Edit"
                                    onClick={() => {
                                        setActiveEditPlanId(_id);
                                    }}
                                    className="text-xs text-[#449745] p-0 bg-transparent border-none cursor-pointer"
                                >
                                    <SvgIcon
                                        icon="EDIT_ICON_PLAN"
                                        className={`${
                                            isSelected
                                                ? 'stroke-white'
                                                : 'stroke-[#2e672f]'
                                        }`}
                                    />
                                </button>
                                <div className="h-4 border border-y-0 border-l-0 border-solid border-gray-300" />
                                <button
                                    type="button"
                                    aria-label="Delete"
                                    onClick={() => {
                                        setIsDeleteModal(true);
                                    }}
                                    className="text-xs text-[#449745] p-0 bg-transparent border-none cursor-pointer"
                                >
                                    <SvgIcon
                                        icon="DELETE_ICON_2"
                                        className="stroke-[#449745]"
                                    />
                                </button>
                            </div>
                        )}
                        {isSelected && (
                            <SvgIcon
                                icon="CHECK_ICON_BILL"
                                className="stroke-white pt-1.5"
                            />
                        )}
                    </div>
                </div>
                <div className="flex gap-x-3 items-end">
                    <div className="font-poppins-bold text-[32px] leading-[26px]">
                        {price} {getCurrencySign(currency)}
                    </div>
                    <div className="text-xs">
                        /per{' '}
                        <span className="capitalize">
                            {unit?.toLowerCase()}
                        </span>
                    </div>
                </div>
                {!auth?.isSuperAdmin && (
                    <PrimaryButton
                        color={
                            isSelected || isCurrentPlan ? 'white' : '#2E672F'
                        }
                        variant="filled"
                        disabled={isSelected || isCurrentPlan}
                        onClick={() => setSelectedPlan(_id)}
                        name={
                            isCurrentPlan
                                ? 'Current Plan'
                                : isSelected
                                ? 'Plan Selected'
                                : 'Select Plan'
                        }
                        className={`w-full disabled:!bg-white disabled:!text-[#2E672F] !h-12 !rounded-xl !text-base ${
                            isSelected ? '!cursor-auto' : '!cursor-pointer'
                        }`}
                    />
                )}
                <div
                    className={`space-y-2 h-[172px] overflow-auto ${
                        auth?.isSuperAdmin ? 'pt-5' : 'pt-0'
                    }`}
                >
                    {features?.map((feature, index) => (
                        <FeatureLine
                            key={index}
                            color={
                                isSelected || isCurrentPlan
                                    ? '#FFFFFF'
                                    : '#449745'
                            }
                            feature={feature}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PlanCard;
