import React, { useEffect, useMemo, useState } from 'react';
import { getTransaction } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { ITransactions } from 'data/types/response';
import { useAuthContext } from 'context/Auth/AuthContext';
import CompanySelectionMessage from 'components/Common/CompanySelectionMessage/CompanySelectionMessage';
import { TransactionHeader } from './TransactionHeader';
import { TransactionTable } from './TransactionTable';
import TransactionTableFilterModal from './TransactionTableFilterModal';
import { headerList } from './TransactionTable/TransactionTableUtill';
import {
    IFetchTransactionPayload,
    IFilteredDataType,
} from './Transaction.type';
import TransactionActionModal from '../../Modal/TransactionActionModal/TransactionActionModal';

const PaymentTransactions = () => {
    const { auth } = useAuthContext();
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [transactionData, setTransactionData] = useState<ITransactions[]>([]);
    const [filterData, setFilterData] = useState<IFilteredDataType>({
        status: [],
        search: '',
        page: 1,
        limit: 10,
    });
    const [isTableFilter, setIsTableFilter] = useState<boolean>(false);
    const [totalPage, setTotalPage] = useState<number>(10);
    const [checkedTransactions, setCheckedTransactions] = useState<string[]>(
        []
    );
    const [isActionModalOpen, setIsActionModalOpen] = useState<boolean>(false);
    const [selectedRowStatus, setSelectedRowStatus] = useState<string>('');

    const updatedHeaderList = useMemo(() => {
        if (auth.authUser?.filteredColumn !== undefined) {
            return auth.authUser?.filteredColumn?.length !== 0
                ? headerList.map((item) => ({
                      ...item,
                      isChecked: (auth.authUser?.filteredColumn || []).includes(
                          item.key
                      ),
                  }))
                : headerList;
        }
        return headerList;
    }, [auth.authUser?.filteredColumn]);

    const fetchTransactionData = () => {
        setIsLoading(true);

        const selectedCompany =
            auth?.selectedCompany ??
            JSON.parse(localStorage.getItem('selectedCompany') ?? '{}');

        const selectedBrand =
            auth?.selectedBrand ??
            JSON.parse(localStorage.getItem('selectedBrand') ?? '{}');

        const payload: IFetchTransactionPayload = { ...filterData };

        if (selectedBrand?._id) {
            payload.brandIds = [selectedBrand?._id];
        } else if (selectedCompany?._id) {
            payload.brandIds = (selectedCompany?.brandData || []).map(
                ({ _id }: { _id: string }) => _id
            );
        }

        if (!payload?.brandIds && !auth?.isSuperAdmin) {
            setIsLoading(false);
            return;
        }

        getTransaction(payload)
            .then((res) => {
                setTotalPage(res?.total);
                setTransactionData(
                    (res?.transactions || []).sort(
                        (a, b) =>
                            new Date(b?.createdAt).getTime() -
                            new Date(a?.createdAt).getTime()
                    ) || []
                );
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                const errorMessage = err?.errors?.[0]?.message ?? err?.message;
                showToast(errorMessage ?? 'something went wrong', 'error');
            });
    };

    useEffect(() => {
        if (!auth?.selectedCompany?._id && !auth?.selectedBrand) return;
        const timeoutId = setTimeout(() => fetchTransactionData(), 300);
        return () => clearTimeout(timeoutId);
    }, [filterData, auth?.selectedBrand?._id, auth?.selectedCompany?._id]);

    if (!auth?.selectedCompany?._id && !auth?.selectedBrand) {
        return <CompanySelectionMessage />;
    }

    return (
        <div className="p-5">
            <TransactionHeader
                setFilterData={setFilterData}
                filterData={filterData}
                checkedTransaction={checkedTransactions}
                setIsTableFilter={setIsTableFilter}
                setIsActionModalOpen={setIsActionModalOpen}
                selectedRowStatus={selectedRowStatus}
            />

            {isTableFilter && (
                <TransactionTableFilterModal
                    className="actionModal"
                    setIsOpen={setIsTableFilter}
                    tableFilterColumn={updatedHeaderList}
                />
            )}

            {isActionModalOpen && (
                <TransactionActionModal
                    checkedTransactions={checkedTransactions}
                    setIsOpen={setIsActionModalOpen}
                    setCheckedTransactions={setCheckedTransactions}
                    selectedRowStatus={selectedRowStatus}
                    fetchTransactionData={fetchTransactionData}
                />
            )}

            <TransactionTable
                tableFilterColumn={updatedHeaderList}
                loading={isLoading}
                currentPage={currentPage}
                transactionData={transactionData}
                filterData={filterData}
                setCurrentPage={setCurrentPage}
                setFilterData={setFilterData}
                totalPage={totalPage}
                setSelectedRowStatus={setSelectedRowStatus}
                setCheckedTransactions={setCheckedTransactions}
                checkedTransactions={checkedTransactions}
            />
        </div>
    );
};
export default PaymentTransactions;
