import React, { Dispatch, SetStateAction } from 'react';
import { z, ZodType } from 'zod';
import { UseFormReset } from 'react-hook-form';

export interface IPlanData {
    _id: string;
    name: string;
    price: number;
    currency: string;
    unit: string;
    type: string;
    features: string[];
}

export interface IPaymentPayload {
    planId: string;
    amount: number;
    status: BILLING_INVOICE_STATUS;
    currency: string;
    paymentDetails: any;
}

export interface IPlanDataProps extends IPlanData {
    setSelectedPlan: Dispatch<SetStateAction<string | null>>;
    selectedPlan: string | null;
    getInvoiceData: () => void;
    fetchPlansData: () => void;
    setActiveEditPlanId: Dispatch<SetStateAction<string>>;
    isEditable?: boolean;
    isChangePlanModal?: boolean;
}

export enum PLAN_DURATIONS_LABEL {
    MONTHLY = 'Monthly',
    YEARLY = 'Yearly',
}

export enum PLAN_DURATIONS_KEYS {
    MONTH = 'MONTH',
    YEAR = 'YEAR',
}

export enum SUBSCRIPTION_PLAN_TYPE {
    CUSTOM = 'CUSTOM',
}

export interface IInvoiceData {
    _id: string;
    planId: string;
    userId: string;
    currency: string;
    status: BILLING_INVOICE_STATUS;
    planName: string;
    startDate: string;
    endDate: string;
    dueDate: string;
    amount: number;
}

export enum BILLING_INVOICE_STATUS {
    PAID = 'PAID',
    OUTSTANDING = 'OUTSTANDING',
}

export enum BILLING_INVOICE_STATUS_LABEL {
    PAID = 'Paid',
    OUTSTANDING = 'Outstanding',
}

export const invoiceFilterOptions = [
    {
        _id: BILLING_INVOICE_STATUS.PAID,
        name: BILLING_INVOICE_STATUS_LABEL.PAID,
    },
    {
        _id: BILLING_INVOICE_STATUS.OUTSTANDING,
        name: BILLING_INVOICE_STATUS_LABEL.OUTSTANDING,
    },
];

export interface IPricingAndBillingsProps {
    invoiceData: IInvoiceData[];
    getInvoiceData: () => void;
    setUpdatePaymentPlanModal: Dispatch<SetStateAction<boolean>>;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export interface IPlansProps {
    plans: IPlanData[];
    invoiceData: IInvoiceData[];
    getInvoiceData: () => void;
    fetchPlansData: () => void;
}

export interface IPaymentCard {
    cardHolderName: string;
    cardNumber: string;
    expiry: string;
    cvv: string;
}

export const addPaymentCardValidation: ZodType<IPaymentCard> = z
    .object({
        cardNumber: z
            .string({ required_error: 'Please enter your card number' })
            .trim()
            .regex(/^\d{16}$/, 'Please enter valid card number'),
        cardHolderName: z
            .string({ required_error: 'Please enter card holder name' })
            .trim()
            .min(3, 'Card holder name must be at least 3 characters')
            .max(50, 'Card holder name must be a maximum of 50 characters')
            .regex(
                /^[A-Za-z\s]+$/,
                'Card holder name must contain only letters and spaces'
            ),
        cvv: z
            .string({ required_error: 'Please enter your CVV number' })
            .trim()
            .regex(/^\d{3}$/, 'CVV number must be exactly 3 digits'),
        expiry: z
            .string({ required_error: 'Please select expiry date' })
            .trim()
            .min(1, 'Please select expiry date'),
    })
    .required();

export interface IPaymentMethodsDrawerProps {
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    isOpen: boolean;
}

export const staticCardData = [
    {
        _id: '1',
        cardHolderName: 'Joshua Jones',
        cardNumber: 4111111111111111,
        expiry: '03/2028',
        isDefault: true,
        cvv: '123',
    },
    {
        _id: '2',
        cardHolderName: 'Daniel Hamilton',
        cardNumber: 5100000000000511,
        expiry: '03/2028',
        isDefault: false,
        cvv: '123',
    },
];

export interface ICardDetailsProps {
    setActiveCard: React.Dispatch<React.SetStateAction<string>>;
    activeCard: string;
    name: string;
    cardNumber: number;
    expiry: string;
    _id: string;
    cvv: string;
    reset: UseFormReset<IPaymentCard>;
    setUpdateCardId: Dispatch<SetStateAction<string | null>>;
}

export interface IPaymentPlanModalProps {
    getInvoiceData: () => void;
    plans: IPlanData[];
    setUpdatePaymentPlanModal: Dispatch<SetStateAction<boolean>>;
    fetchPlansData: () => void;
}

declare global {
    interface Window {
        SecureTrading?: any;
    }
}

export interface IAddCardFormProps {
    selectedPlanData?: IPlanData | null;
    setAddPaymentCardModal: Dispatch<SetStateAction<boolean>>;
    setPaymentStatusModal: Dispatch<SetStateAction<boolean>>;
}

export interface ICurrentPlanData {
    _id: string;
    planId: string;
    planName: string;
    amount: number;
    currency: string;
    endDate: string;
    unit: string;
}
