import { IMenuItem } from '../common';

export enum PageIdentifiers {
    USER_MANAGEMENT = 'USER_MANAGEMENT',
    COMPANIES = 'COMPANIES',
    ACTIVITY_LOG = 'ACTIVITY_LOG',
    GROUPS = 'GROUPS',
    SUB_BRANDS = 'SUB_BRANDS',
    API_KEYS = 'API_KEYS',
    OPERATIONS = 'OPERATIONS',
    APPROVALS = 'APPROVALS',
    PAYMENT_METHODS = 'PAYMENT_METHODS',
    CONFIGURATION = 'CONFIGURATION',
    SIMULATOR = 'SIMULATOR',
    ADMIN = 'ADMIN',
    TRANSACTIONS = 'TRANSACTIONS',
    WORKFLOWS = 'WORKFLOWS',
    BILLING_INFORMATION = 'BILLING_INFORMATION',
}

export const SIDE_MENU_LIST: IMenuItem[] = [
    {
        id: 2,
        icon: 'AUDIT_ICON',
        path: '/operations',
        key: PageIdentifiers.OPERATIONS,
        title: 'Operations',
        subMenu: [
            {
                id: 1,
                title: 'Transactions',
                key: PageIdentifiers.TRANSACTIONS,
                path: '/operations/transactions',
            },
            {
                id: 2,
                title: 'Approvals',
                key: PageIdentifiers.APPROVALS,
                path: '/operations/approvals',
            },
        ],
    },
    {
        id: 3,
        icon: 'AUDIT_ICON',
        title: 'Orchestration',
        key: PageIdentifiers.WORKFLOWS,
        path: '/workflows/orchestration',
        subMenu: [],
    },
    {
        id: 4,
        icon: 'PAYMENT_ICON',
        title: 'Payment Methods',
        key: PageIdentifiers.PAYMENT_METHODS,
        path: '/payment-methods',
        subMenu: [
            {
                id: 1,
                title: 'Configuration',
                key: PageIdentifiers.CONFIGURATION,
                path: '/payment-methods/configuration',
            },
            {
                id: 2,
                title: 'Simulator',
                key: PageIdentifiers.SIMULATOR,
                path: '/payment-methods/simulator',
            },
        ],
    },
    {
        id: 6,
        icon: 'ADMIN_ICON',
        path: '/admin',
        key: PageIdentifiers.ADMIN,
        title: 'Admin Center',
        subMenu: [
            {
                id: 1,
                title: 'User Management',
                key: PageIdentifiers.USER_MANAGEMENT,
                path: '/admin/existing-users',
            },
            {
                id: 2,
                title: 'Companies',
                key: PageIdentifiers.COMPANIES,
                path: '/admin/companies',
            },
            {
                id: 4,
                key: PageIdentifiers.ACTIVITY_LOG,
                path: '/admin/activity-log',
                title: 'Activity Log',
            },
            {
                id: 3,
                title: 'Groups',
                key: PageIdentifiers.GROUPS,
                path: '/admin/groups',
            },
            {
                id: 4,
                title: 'Sub Brands',
                key: PageIdentifiers.SUB_BRANDS,
                path: '/admin/brands',
            },
            {
                id: 5,
                title: 'API Credentials',
                key: PageIdentifiers.API_KEYS,
                path: '/admin/api-keys',
            },
        ],
    },
];

export enum GenderConstant {
    SELECT_GENDER = '-Select-',
    MALE = 'Male',
    FEMALE = 'Female',
    OTHER = 'Other',
}

export const GenderList = [
    {
        _id: '',
        name: GenderConstant.SELECT_GENDER,
    },
    { _id: GenderConstant.MALE, name: GenderConstant.MALE },
    { _id: GenderConstant.FEMALE, name: GenderConstant.FEMALE },
    {
        _id: GenderConstant.OTHER,
        name: GenderConstant.OTHER,
    },
];

export enum CurrenciesConstant {
    SELECT_CURRENCY = '-Select-',
    CLP = 'CLP',
    USD = 'USD',
    EURO = 'EURO',
    EUR = 'EUR',
}

export enum UserRoles {
    SUPER_ADMIN = 'SUPER_ADMIN',
    ADMIN = 'ADMIN',
    USER = 'USER',
}

export enum BulkActionTypes {
    DELETE = 'Delete',
    DISABLE = 'Disable',
    RESET = 'Reset password attempts',
    REACTIVE = 'Reactive',
    BULK_ACTION = 'Bulk Changes',
}

export enum ModalConstants {
    PAYMENT = 'Payment',
    USER_INFORMATION = 'User Information',
    TRANSACTION_INFORMATION = 'Transaction Information',
    KYC_STATUS = 'KYC Status',
    OTHER = 'Other',
    FINANCIAL = 'Financial',
    RAW_RESPONSE = 'Raw Response',
}

export const VelocityWindowOpts = [
    {
        _id: '',
        name: `Select time window`,
    },
    {
        _id: 'HOURS',
        name: 'Hours',
    },
    {
        _id: 'DAYS',
        name: 'Days',
    },
    {
        _id: 'MONTHS',
        name: 'Months',
    },
];

export const VelocityTypeOpts = [
    {
        _id: '',
        name: `Select type`,
    },
    {
        _id: 'MAX_COUNT',
        name: 'Max Count',
    },
    {
        _id: 'MAX_AMOUNT',
        name: 'Max Amount',
    },
];

export enum TransactionStatusLabels {
    ALL_PAYMENTS = 'All Payments',
    PENDING = 'Pending',
    MANUAL = 'Manual',
    SUSPENDED = 'Suspended',
    CANCELLED = 'Cancelled',
    PAID = 'Paid',
    SUCCESS = 'Success',
    EXPIRED = 'Expired',
    FAILED = 'Failed',
    ERROR = 'Error',
    CAPTURED = 'Captured',
    HOLD = 'Hold',
    REJECT = 'Reject',
    INITIATED = 'Initiated',
    NOT_VERIFIED = 'Not Verified',
    VERIFIED = 'Verified',
    APPROVE = 'Approve',
}

export enum TransactionStatusKeys {
    ALL_PAYMENTS = 'ALL_PAYMENTS',
    PENDING = 'PENDING',
    MANUAL = 'MANUAL',
    SUSPENDED = 'SUSPENDED',
    CANCELLED = 'CANCELLED',
    PAID = 'PAID',
    SUCCESS = 'SUCCESS',
    EXPIRED = 'EXPIRED',
    FAILED = 'FAILED',
    ERROR = 'ERROR',
    CAPTURED = 'CAPTURED',
    HOLD = 'HOLD',
    REJECT = 'REJECT',
    INITIATED = 'Initiated',
    NOT_VERIFIED = 'NOT_VERIFIED',
    VERIFIED = 'VERIFIED',
    APPROVE = 'APPROVE',
    SETTLING = 'SETTLING',
}
