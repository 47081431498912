import React, { useState } from 'react';
import {
    BILLING_INVOICE_CURRENCY,
    currencyOptions,
} from 'data/constants/payment.constants';
import { saveSubscriptionPlan, updateSubscriptionPlan } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { useAuthContext } from 'context/Auth/AuthContext';
import { PrimaryButton, Select, SvgIcon } from 'components/Common';
import EditableFeatureLine from '../EditableFeatureLine/EditableFeatureLine';
import { SUBSCRIPTION_PLAN_TYPE } from '../../../Billing.types';

interface IEditablePlanCardProps {
    duration: string;
    fetchPlansData: () => void;
    defaultPlanName?: string;
    defaultPrice?: number;
    defaultCurrency?: BILLING_INVOICE_CURRENCY;
    defaultFeatureList?: string[];
    defaultIsAdded?: boolean;
    onCancel?: () => void;
    planId?: string;
}

const EditablePlanCard = ({
    duration,
    fetchPlansData,
    onCancel,
    defaultCurrency,
    defaultFeatureList,
    defaultIsAdded,
    defaultPlanName,
    defaultPrice,
    planId,
}: IEditablePlanCardProps) => {
    const [isAdded, setIsAdded] = useState(defaultIsAdded ?? false);
    const [planName, setPlanName] = useState(defaultPlanName ?? 'Custom');
    const [price, setPrice] = useState(defaultPrice ?? '99');
    const [currency, setCurrency] = useState(
        defaultCurrency ?? BILLING_INVOICE_CURRENCY.USD
    );
    const [featureList, setFeatureList] = useState<string[]>(
        defaultFeatureList ?? []
    );
    const [hoveredFeature, setHoveredFeature] = useState<number | null>(null);
    const [loading, setLoading] = useState(false);
    const { auth } = useAuthContext();

    const cancelPlanAddition = () => {
        if (onCancel) {
            onCancel();
        } else {
            setIsAdded(false);
            setPlanName('Custom');
            setPrice('99');
            setCurrency(BILLING_INVOICE_CURRENCY.USD);
            setFeatureList([]);
        }
    };

    const handleFeatureAdd = () => {
        setFeatureList([
            ...featureList,
            'Manage up to 1,000 payment providers',
        ]);
    };

    const handleFeatureRemove = (index: number) => {
        const newFeatureList = featureList.filter((_, i) => i !== index);
        setFeatureList(newFeatureList);
    };

    const handleFeatureChange = (index: number, value: string) => {
        const newFeatureList = featureList.map((feature, i) => {
            if (i === index) {
                return value;
            }
            return feature;
        });
        setFeatureList(
            newFeatureList.filter((feature) => feature.trim() !== '')
        );
    };

    const handlePlanSave = async () => {
        const payload = {
            name: planName,
            price: Number(price),
            currency,
            features: featureList,
            duration,
            companyId: auth?.selectedCompany?._id,
            unit: duration?.toUpperCase(),
            type: SUBSCRIPTION_PLAN_TYPE.CUSTOM,
        };

        if (featureList.length === 0) {
            showToast('Please add at least one feature.', 'error');
            return;
        }
        try {
            setLoading(true);
            const response = await saveSubscriptionPlan(payload);
            showToast(response?.message, 'success');
            setIsAdded(false);
            cancelPlanAddition();
            fetchPlansData();
        } catch (err: any) {
            let errorMessage = err?.message;
            if (err?.errors?.[0]?.msg) {
                errorMessage = err?.errors?.[0]?.msg;
            }
            showToast(errorMessage || err?.message, 'error');
        } finally {
            setLoading(false);
        }
    };

    const handlePlanUpdate = async () => {
        const payload = {
            name: planName,
            price: Number(price),
            currency,
            features: featureList,
            unit: duration?.toUpperCase(),
            companyId: auth?.selectedCompany?._id,
        };

        if (featureList.length === 0) {
            showToast('Please add at least one feature.', 'error');
            return;
        }
        if (!planId) {
            showToast('Invalid Plan Id', 'error');
            return;
        }
        try {
            setLoading(true);
            const response = await updateSubscriptionPlan(planId, payload);
            showToast(response?.message, 'success');
            setIsAdded(false);
            cancelPlanAddition();
            fetchPlansData();
        } catch (err: any) {
            let errorMessage = err?.message;
            if (err?.errors?.[0]?.msg) {
                errorMessage = err?.errors?.[0]?.msg;
            }
            showToast(errorMessage || err?.message, 'error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="transition-all duration-200 bg-[#F8FBEA] h-[384px] box-border text-black p-1 rounded-[10px] w-[360px] shadow-md">
            <div
                className={`bg-white ${
                    isAdded ? '' : 'flex items-center justify-center'
                }  text-black rounded-[10px] h-[376px] box-border px-[30px] pt-[26px] pb-8 space-y-4 transition-all duration-200`}
            >
                {isAdded ? (
                    <>
                        <div className="flex items-center justify-between h-6">
                            <div className="font-poppins-bold text-base leading-[20px]">
                                <input
                                    onChange={(e) => {
                                        setPlanName(e.target.value);
                                    }}
                                    value={planName}
                                    maxLength={50}
                                    className="!border-transparent w-full !border-2 px-2 -ms-2 bg-transparent font-poppins-bold text-base leading-[20px]"
                                />
                            </div>
                        </div>
                        <div className="flex gap-x-3 items-end !mt-1">
                            <div className="font-poppins-bold text-[32px] leading-[26px]">
                                <div className="flex items-end">
                                    <input
                                        onChange={(e) => {
                                            let value = e.target.value;

                                            if (value.length > 7) {
                                                value = value.slice(0, 7);
                                            }

                                            setPrice(value);
                                        }}
                                        onInput={(e) => {
                                            const input =
                                                e.target as HTMLInputElement;
                                            input.style.width = `${Math.max(
                                                40,
                                                input.value.length * 20
                                            )}px`;
                                        }}
                                        style={{
                                            width:
                                                Math.max(
                                                    40,
                                                    price
                                                        ? (typeof price ===
                                                          'string'
                                                              ? price.length
                                                              : price.toString()
                                                                    .length) *
                                                              20
                                                        : 40
                                                ) + 5,
                                        }}
                                        value={price}
                                        type="number"
                                        maxLength={8}
                                        className="!border-transparent w-10 min-w-10 !border-2 ps-2 !py-0 -ms-2 bg-transparent font-poppins-bold text-[32px] leading-[26px]
               [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none [&::-moz-appearance]:textfield"
                                    />
                                    <Select
                                        isMarginBottom={false}
                                        isGroup
                                        className="!bg-white font-poppins-bold text-center text-[32px] leading-[26px] !p-0 !ps-1 !pr-7 !mb-1 !rounded-[10px] !border-none h-8 shadow-none"
                                        options={currencyOptions}
                                        value={currency}
                                        handleOnSelect={(e) =>
                                            setCurrency(
                                                e.target
                                                    .value as BILLING_INVOICE_CURRENCY
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            <div className="text-xs mb-2.5 -ms-5">
                                /per{' '}
                                <span className="capitalize">
                                    {duration?.toLowerCase()}
                                </span>
                            </div>
                        </div>
                        <div className="space-y-2 py-5 max-h-[110px] overflow-auto">
                            {featureList.map((feature, index) => (
                                <div
                                    className="flex items-center gap-1 justify-between"
                                    onMouseEnter={() =>
                                        setHoveredFeature(index)
                                    }
                                    onMouseLeave={() => setHoveredFeature(null)}
                                >
                                    <EditableFeatureLine
                                        key={index}
                                        feature={feature}
                                        handleFeatureChange={
                                            handleFeatureChange
                                        }
                                        index={index}
                                    />
                                    {hoveredFeature === index && (
                                        <PrimaryButton
                                            color="#8FB131"
                                            variant="filled"
                                            name="X"
                                            className="!h-auto !py-0.5"
                                            onClick={() =>
                                                handleFeatureRemove(index)
                                            }
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                        <PrimaryButton
                            color="#2E672F"
                            variant="filled"
                            name="+ Add Feature"
                            className="w-full"
                            onClick={handleFeatureAdd}
                        />
                        <div className="grid grid-cols-2 gap-6">
                            <PrimaryButton
                                color="#2E672F"
                                variant="filled"
                                isSmallButton
                                name={
                                    loading
                                        ? ''
                                        : defaultIsAdded
                                        ? 'Update'
                                        : 'Save'
                                }
                                disabled={loading}
                                loading={loading}
                                className="w-full"
                                onClick={
                                    defaultIsAdded
                                        ? handlePlanUpdate
                                        : handlePlanSave
                                }
                            />
                            <PrimaryButton
                                color="#8FB131"
                                variant="filled"
                                name="Cancel"
                                disabled={loading}
                                onClick={cancelPlanAddition}
                                className="w-full"
                            />
                        </div>
                    </>
                ) : (
                    <div className="flex flex-col items-center">
                        <button
                            type="button"
                            onClick={() => {
                                setIsAdded(true);
                            }}
                            className="bg-transparent border-none cursor-pointer"
                            aria-label="add plan"
                        >
                            <div>
                                <SvgIcon
                                    icon="BIG_PLUS_ICON"
                                    className="cursor-pointer"
                                />
                            </div>
                            <div className="font-poppins-bold text-[#2E672F] text-xl">
                                Add new plan
                            </div>
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default EditablePlanCard;
